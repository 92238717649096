import { LURLReportAction, LURLReportActionTypes, LURLReportState } from '../types/lurl';

const defaultState: LURLReportState = {
    lurlStats: [],
    lurlReasons: [],
    loading: false,
    error: null,
};

const LURLReportReducer = (state = defaultState, action: LURLReportAction): LURLReportState => {
    switch (action.type) {
    case LURLReportActionTypes.FETCH_LURL_REPORT_REQUEST:
        return { ...state, loading: true, error: null };
    case LURLReportActionTypes.FETCH_LURL_REPORT_SUCCESS:
        return { ...state, lurlStats: action.payload, loading: false, error: null };
    case LURLReportActionTypes.FETCH_LURL_REPORT_ERROR:
        return { ...state, error: action.payload, loading: false };
    case LURLReportActionTypes.FETCH_LURL_REASONS_REQUEST:
        return { ...state, loading: true, error: null };
    case LURLReportActionTypes.FETCH_LURL_REASONS_SUCCESS:
        return { ...state, lurlReasons: action.payload, loading: false };
    case LURLReportActionTypes.FETCH_LURL_REASONS_ERROR:
        return { ...state, error: action.payload, loading: false };
    default:
        return state;
    }
};

export default LURLReportReducer;
