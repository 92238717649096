import { useState } from 'react';
import { Modal, Form, Button, notification } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';
import BundleForm from '../forms/BundleForm';
import Bundle from '../../models/bundle';
import formType from '../../types/form';
import { CountryPrice, DefaultPrice } from '../../models/price';
import { Country } from '../../models/country';
import { UpdateAction } from '../../store/types/actions';

interface EditBundleProps {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    bundle: Bundle;
    defaultPrice: DefaultPrice;
    countries: Country[];
    error: null | string;
    updateBundle: UpdateAction<Bundle, void>;
}

const EditBundle: React.FC<EditBundleProps> = props => {
    const { open, setOpen, bundle, defaultPrice, countries, error, updateBundle } = props;
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [form] = Form.useForm();

    const getPricesByCountryFromForm = (input: any): CountryPrice[] => {
        if (!input) {
            return [];
        }

        const typed: CountryPrice[] = input.map((countryPrice: any) => ({
            country: countryPrice.country,
            price: +countryPrice.price,
        }
        ));
        const countryPricesMap: { [key: string]: number } = typed.reduce((prev, current) => {
            const { country, price } = current;
            return { ...prev, [country]: price };
        }, {});
        const result: CountryPrice[] = Object
            .entries(countryPricesMap).map(([country, price]) => ({ country, price }));

        return result;
    };

    const handleOk = async (values: { [key: string]: string }) => {
        const { name, url, gp_url, price, price_by_country } = values;
        const pricesByCountry = getPricesByCountryFromForm(price_by_country);

        const updatedBundle: Bundle = {
            id: bundle.id,
            name,
            url,
            gp_url,
            price: +price,
            price_by_country: pricesByCountry,
            updated_by: '',
        };
        setConfirmLoading(true);
        await updateBundle(updatedBundle);
        setConfirmLoading(false);
        // TODO error is empty on first unsuccessful request, but on second and onward it's ok.. what the fuck
        console.log('error: ', error);
        if (!error) {
            setOpen(false);
            notification.open({
                message: `${name} bundle updated`,
                icon: <CheckCircleOutlined style={{ color: '#108ee9' }} />,
            });
        }
    };

    const handleCancel = () => setOpen(false);

    return (
        <Modal
            title="Edit Bundle"
            getContainer={false}
            open={open}
            confirmLoading={confirmLoading}
            onCancel={handleCancel}
            footer={[
                <Button key="cancel" onClick={handleCancel}>
                    Cancel
                </Button>,
                <Button form="bundleForm" key="submit" htmlType="submit" type="primary" loading={confirmLoading}>
                    Submit
                </Button>,
            ]}
        >
            <BundleForm
                form={form}
                bundle={bundle}
                defaultPrice={defaultPrice}
                countries={countries}
                onFinish={handleOk}
                type={formType.EDIT_FORM}
            />
        </Modal>
    );
};

export default EditBundle;
