import { useState } from 'react';
import { Dispatch } from 'redux';
import { Button, DatePicker, DatePickerProps, Input, Typography, Row, Col } from 'antd';
import dayjs from 'dayjs';
import { IncomingReportAction } from '../../store/types/incoming';
import { RootState } from '../../store/reducers';
import { formatDate } from '../../helpers/date';
import { IncomingInfo } from '../../models/incoming';
import IncomingReportCSV from './IncomingReportCSV';
import CountrySelect from '../common/CountrySelect';
import { Country } from '../../models/country';
import ProviderSelect from '../common/ProviderSelect';
import { ProviderListItem } from '../../models/provider';
import ThunkArguments from '../../store/types/common';

const { Text } = Typography;

interface IncomingReportBarProps {
    fetchIncomingReport: (from: string, to: string, cr_id: string, country_code?: string,
        provider?: string, rp_title?: string) => (dispatch: Dispatch<IncomingReportAction>,
        getState: () => RootState, args: ThunkArguments) => Promise<void>;
    incomingStats: IncomingInfo[];
    countries: Country[];
    providers: ProviderListItem[];
}

const dateFormat = 'YYYY-MM-DD';
const maxTimeDifference = 7776000000;

const IncomingReportBar: React.FC<IncomingReportBarProps> = props => {
    const { fetchIncomingReport, incomingStats, countries, providers } = props;

    const getDefaultFrom = () => {
        const date = new Date();
        date.setDate(date.getDate() - 1);
        return formatDate(date.toString());
    };

    const getDefaultTo = () => {
        const date = new Date();
        return formatDate(date.toString());
    };

    const [from, setFrom] = useState(getDefaultFrom());
    const [to, setTo] = useState(getDefaultTo());
    const [crID, setCrID] = useState('');
    const [rpTitle, setRPTitle] = useState('');
    const [country, setCountry] = useState<string | undefined>(undefined);
    const [provider, setProvider] = useState<string | undefined>(undefined);
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const onFromChange: DatePickerProps['onChange'] = (date, dateString) => {
        setFrom(dateString);
    };

    const onToChange: DatePickerProps['onChange'] = (date, dateString) => {
        setTo(dateString);
    };

    const search = () => {
        setShowErrorMessage(false);
        if (crID === '') {
            setShowErrorMessage(true);
            setErrorMessage('Creative ID should not be empty!');
            return;
        }
        const fromTime = new Date(from).getTime();
        const toTime = new Date(to).getTime();
        const diff = toTime - fromTime;
        if (diff > maxTimeDifference) {
            setShowErrorMessage(true);
            setErrorMessage('Time range must be lower than 90 days!');
            return;
        }
        fetchIncomingReport(from, to, crID.trim(), country, provider, rpTitle.trim());
    };

    return (
        <>
            <Row
                gutter={10}
                justify="start"
                align="middle"
                wrap={false}
                style={{ width: '70%', margin: 'auto', marginBottom: 16 }}
            >
                <Col style={{ whiteSpace: 'nowrap' }}>
                    <Text>From: </Text><DatePicker onChange={onFromChange} defaultValue={dayjs(getDefaultFrom(), dateFormat)} />
                </Col>
                <Col style={{ whiteSpace: 'nowrap' }}>
                    <Text>To: </Text><DatePicker onChange={onToChange} defaultValue={dayjs(getDefaultTo(), dateFormat)} />
                </Col>
                <Col style={{ whiteSpace: 'nowrap' }}>
                    <Text>Creative ID: </Text><Input type="text" onChange={e => setCrID(e.target.value)} />
                </Col>
            </Row>
            <Row
                gutter={10}
                justify="start"
                align="middle"
                wrap={false}
                style={{ width: '70%', margin: 'auto', marginBottom: 16 }}
            >
                <Col style={{ whiteSpace: 'nowrap' }}><Text>RP title: </Text></Col>
                <Col>
                    <Input type="text" onChange={e => setRPTitle(e.target.value)} />
                </Col>
                <Col>
                    {providers.length && <ProviderSelect providers={providers} onChange={setProvider} />}
                </Col>
                <Col>
                    <CountrySelect countries={countries} onChange={setCountry} />
                </Col>
                <Col>
                    {showErrorMessage && <Text style={{ color: 'red' }}>{errorMessage}</Text>}
                </Col>
            </Row>
            <Row
                gutter={10}
                justify="start"
                align="middle"
                wrap={false}
                style={{ width: '70%', margin: 'auto', marginBottom: 16 }}
            >
                <Col>
                    <Button onClick={search} type="primary">
                        Search
                    </Button>
                </Col>
                <Col>
                    <IncomingReportCSV incomingStats={incomingStats} />
                </Col>
            </Row>
        </>
    );
};

export default IncomingReportBar;
