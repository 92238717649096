import { useState } from 'react';
import { Form, FormInstance, InputNumber, Alert } from 'antd';
import { Country, WeightKind } from '../../models/country';
import Bundle from '../../models/bundle';
import CountrySelect from '../common/CountrySelect';
import { ProviderListItem, ProviderName } from '../../models/provider';

interface CountryProvidersFormProps {
    form: FormInstance<Bundle>;
    countries: Country[];
    providersList: ProviderListItem[];
    kind: WeightKind;
    onFinish: ((values: any) => void);
}

const CountryProvidersForm: React.FC<CountryProvidersFormProps> = props => {
    const { countries, providersList, kind, onFinish } = props;
    const weightRegex = /^[0-9][0-9]?$|^100$/;
    const minPriceScale = 0;
    const maxPriceScale = 200;

    const defaultValue = providersList.map(provider => ({
        provider: provider.provider_name,
        weight: 0,
    }));

    const [weights, setWeights] = useState<{provider: ProviderName, weight: number}[]>(defaultValue);
    const [currCountry, setCurrCountry] = useState<string>('');
    const [validateError, setValidateError] = useState<string>();

    const countryRules = [
        { required: true, message: 'Please select country!' },
    ];

    const weightRules = [
        { pattern: weightRegex, message: 'Please input a positive integer number! (in percent)' },
    ];

    const percentParser = (value: string | undefined): number => value ? +value.replace('%', '') : 0;

    const onWeightChange = (provider: string, val: number | null) => {
        if (!val) return;
        const newWeight = weights.map(w => {
            if (w.provider === provider) (w = { provider, weight: val });
            return w;
        });
        setWeights(newWeight);
    };

    const handleOk = (values: { [key: string]: any }) => {
        const { country } = values;
        const sum: number = weights.reduce((prev, curr) => prev + curr.weight, 0);
        if (sum !== 100) {
            setValidateError('Sum of providers weight must be 100');
            return;
        }
        const bidFloorPriceScale: number = +values.bid_floor_price_scale;
        if (bidFloorPriceScale < minPriceScale || bidFloorPriceScale > maxPriceScale) {
            setValidateError(`Bid floor price scale must be between ${minPriceScale} and ${maxPriceScale}`);
            return;
        }
        const providerPriceScale: number = +values.provider_price_scale;
        if (providerPriceScale < minPriceScale || providerPriceScale > maxPriceScale) {
            setValidateError(`Provider price scale must be between ${minPriceScale} and ${maxPriceScale}`);
            return;
        }
        onFinish({ country, weights, bid_floor_price_scale: bidFloorPriceScale, provider_price_scale: providerPriceScale });
    };

    const onFinishFailed = (errorInfo: any) => console.log('Failed:', errorInfo);

    const isBiddingKind = () => kind === WeightKind.Bidding || kind === WeightKind.BiddingInternal;

    const renderPriceScales = () => (
        [
            {
                label: 'Bid floor price scale',
                name: 'bid_floor_price_scale',
            },
            {
                label: 'Provider price scale',
                name: 'provider_price_scale',
            },
        ]
            .map(({ label, name }) => (
                <Form.Item
                    name={name}
                    label={label}
                    key={name}
                    labelAlign="left"
                >
                    <InputNumber
                        min={minPriceScale}
                        max={maxPriceScale}
                        formatter={value => value ? `${value}%` : ''}
                        parser={percentParser}
                    />
                </Form.Item>
            ))
    );

    return (
        <Form
            id="bundleForm"
            layout="horizontal"
            labelCol={{ span: 8, offset: 4 }}
            labelWrap
            wrapperCol={{ span: 10 }}
            onFinish={handleOk}
            onFinishFailed={onFinishFailed}
        >
            <Form.Item
                label="Country"
                labelAlign="left"
                name="country"
                rules={countryRules}
            >
                <CountrySelect countries={countries} onSelect={setCurrCountry} />
            </Form.Item>
            {isBiddingKind() && renderPriceScales()}
            {validateError && <Alert style={{ marginBottom: '20px' }} message={validateError} type="error" />}
            {providersList.map(provider => (provider.allowed_countries.includes(currCountry, 0) && (
                <Form.Item
                    key={provider.provider_name}
                    label={provider.provider_name}
                    labelAlign="left"
                    style={{ marginBottom: 10 }}
                    rules={weightRules}
                >
                    <InputNumber
                        min={0}
                        max={100}
                        name={provider.provider_name}
                        formatter={value => value ? `${value}%` : ''}
                        parser={percentParser}
                        onChange={val => onWeightChange(provider.provider_name, val)}
                    />
                </Form.Item>
            )))}
        </Form>
    );
};
export default CountryProvidersForm;
