import { useState } from 'react';
import { Modal, Form, Button } from 'antd';
import BundleWeightUpdateForm from '../forms/BundleWeightUpdateForm';
import { ProviderListItem } from '../../models/provider';
import { Country, getCountryFullName } from '../../models/country';
import { UpdateAction } from '../../store/types/actions';
import { BundleWeight } from '../../models/bundle';
import { defaultNotify } from '../common/Notify';

interface EditWeightModalProps {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    weight: BundleWeight;
    countries: Country[];
    error: null | string;
    providersList: ProviderListItem[];
    updateBundleWeight: UpdateAction<BundleWeight, void>;
}

const EditWeightModal: React.FC<EditWeightModalProps> = props => {
    const { open, setOpen, error, weight, countries, providersList, updateBundleWeight } = props;
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [form] = Form.useForm();

    // eslint-disable-next-line
    const getSupportingProviders = (): ProviderListItem[] => {
        return providersList.filter(provider => provider.allowed_countries.includes(weight.country));
    };

    const handleOk = async (values: Record<string, any>) => {
        const { id, bundle, country, updated_by } = weight;
        const { weights } = values;
        const updatedWeight: BundleWeight = {
            id,
            bundle,
            country,
            weights,
            updated_by,
        };
        setConfirmLoading(true);
        await updateBundleWeight(updatedWeight);
        setConfirmLoading(false);
        console.log('error: ', error);
        if (!error) {
            setOpen(false);
            defaultNotify(`${bundle} updated`, '');
        }
    };

    const handleCancel = () => setOpen(false);

    const getTitle = (bundle: string, country: string): string => `Edit ${bundle} bundle weight for ${getCountryFullName(country)} country`;

    return (
        <Modal
            title={getTitle(weight.bundle, weight.country)}
            getContainer={false}
            open={open}
            confirmLoading={confirmLoading}
            onCancel={handleCancel}
            footer={[
                <Button key="cancel" onClick={handleCancel}>
                    Cancel
                </Button>,
                <Button form="bundleWeightUpdateForm" key="submit" htmlType="submit" type="primary" loading={confirmLoading}>
                    Submit
                </Button>,
            ]}
        >
            <BundleWeightUpdateForm
                form={form}
                weight={weight}
                providersList={getSupportingProviders()}
                onFinish={handleOk}
            />
        </Modal>
    );
};

export default EditWeightModal;
