/**
 * withKeys adds array index as a unique key
 * @param input array
 * @returns with key
 */
function withKeys<T>(input: Array<T>): Array<{ key: string; } & T> {
    if (!input || input.length === 0) return [];
    return input.map((item, index) => (
        {
            key: index.toString(),
            ...item,
        }
    ));
}

export {
    // eslint-disable-next-line
    withKeys,
};
