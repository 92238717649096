import { createContext, useMemo } from 'react';

interface context {
    email: string;
    isDarkMode: boolean;
    setIsDarkMode: () => void;
}

const defaultContext: context = {
    email: '',
    isDarkMode: false,
    setIsDarkMode: () => undefined,
};
export const Context = createContext<context>(defaultContext);

interface ContextProviderProps {
    value: context;
    children: React.ReactNode;
}

export const ContextProvider: React.FC<ContextProviderProps> = ({ value, children }) => {
    const { email, isDarkMode, setIsDarkMode } = value;
    const memo = useMemo<context>(() => ({ email, isDarkMode, setIsDarkMode }), [email, isDarkMode, setIsDarkMode]);

    return (
        <Context.Provider value={memo}>
            {children}
        </Context.Provider>
    );
};
