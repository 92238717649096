import { BiddingReportState, BiddingReportAction, BiddingReportActionTypes } from '../types/bidding';

const defaultState: BiddingReportState = {
    bidFloorStats: [],
    loading: false,
    error: null,
};

const biddingReportReducer = (state = defaultState, action: BiddingReportAction): BiddingReportState => {
    switch (action.type) {
    case BiddingReportActionTypes.FETCH_BIDFLOOR_REPORT_REQUEST:
        return { ...state, loading: true, error: null };
    case BiddingReportActionTypes.FETCH_BIDFLOOR_REPORT_SUCCESS:
        return { ...state, bidFloorStats: action.payload, loading: false, error: null };
    case BiddingReportActionTypes.FETCH_BIDFLOOR_REPORT_ERROR:
        return { ...state, error: action.payload, loading: false };
    default:
        return state;
    }
};

export default biddingReportReducer;
