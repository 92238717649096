import { useEffect, useState } from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Typography } from 'antd';
import type { CheckboxValueType } from 'antd/es/checkbox/Group';
import * as ProviderActionCreators from '../../store/actions/providerAction';
import * as CountriesActionCreators from '../../store/actions/countryAction';
import * as ErrorReportActionCreators from '../../store/actions/errorReportAction';
import { RootState } from '../../store/reducers';
import { errorNotify } from '../common/Notify';
import ErrorReportTable from './ErrorReportTable';
import ErrorReportBar from './ErrorReportBar';

const { Title } = Typography;

const mapStateToProps = (state: RootState) => ({
    countryState: state.country,
    providerState: state.provider,
    errorReportState: state.errorReport,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
    const boundActions = bindActionCreators({
        ...ProviderActionCreators,
        ...CountriesActionCreators,
        ...ErrorReportActionCreators,
    }, dispatch);
    return {
        ...boundActions,
    };
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type ErrorReportProps = StateProps & DispatchProps;

const ErrorReport: React.FC<ErrorReportProps> = props => {
    const { countryState, providerState, errorReportState } = props;
    const { countries, error: errorCountry } = countryState;
    const { providersList, error: errorProvider } = providerState;
    const { errorReportStats, error: errorErrorReport, loading } = errorReportState;
    const { fetchCountries, fetchProvidersList, fetchErrorReport } = props;

    const groupByOptions: Record<string, string> = {
        region: 'Region',
        kind_app: 'API',
        traffic_distribute_mode: 'Traffic distribute mode',
        content_provider: 'Content provider',
        country_code: 'Country',
        rq_bundle: 'Bundle',
        v_provider: 'Provider',
        user_category: 'User category',
        user_consent: 'User consent',
    };
    const [groupByList, setGroupByList] = useState<string[]>([]);
    const onGroupByChange = (checkedValues: CheckboxValueType[]) => {
        setGroupByList(checkedValues.map(item => item.toString()));
    };

    useEffect(() => {
        fetchCountries();
        fetchProvidersList();
    }, []);

    useEffect(() => {
        if (errorCountry) errorNotify('Country error', errorCountry);
    }, [errorCountry]);

    useEffect(() => {
        if (errorProvider) errorNotify('Provider error', errorProvider);
    }, [errorProvider]);

    useEffect(() => {
        if (errorErrorReport) errorNotify('Error report error', errorErrorReport);
    }, [errorErrorReport]);

    return (
        <>
            <Title level={2} style={{ marginTop: 0 }}>Error Report</Title>
            <ErrorReportBar
                fetchErrorReport={fetchErrorReport}
                errorReportStats={errorReportStats}
                countriesList={countries}
                providersList={providersList}
                groupByList={groupByList}
                groupByOptions={groupByOptions}
                onGroupByChange={onGroupByChange}
            />
            <ErrorReportTable
                loading={loading}
                error={errorErrorReport}
                errorReportStats={errorReportStats}
                countriesList={countries}
                groupByList={groupByList}
            />
        </>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(ErrorReport);
