import { useState } from 'react';
import { Dispatch } from 'redux';
import { Button, Checkbox, DatePicker, DatePickerProps, Select, Typography, Row, Col } from 'antd';
import dayjs from 'dayjs';
import type { CheckboxValueType } from 'antd/es/checkbox/Group';
import { RootState } from '../../store/reducers';
import { formatDate } from '../../helpers/date';
import { ErrorReport, FetchErrorReportRequest } from '../../models/errorReport';
import { ErrorReportAction } from '../../store/types/errorReport';
import { ProviderListItem } from '../../models/provider';
import { Country } from '../../models/country';
import ProviderSelect from '../common/ProviderSelect';
import CountrySelect from '../common/CountrySelect';
import TDMSelect from '../common/TDMSelect';
import KindAppSelect from '../common/KindAppSelect';
import StatusCodeSelect from '../common/StatusCodeSelect';
import BundleSelect from '../common/BundleSelect';
import { AWSRegionTypeMap } from '../../models/awsRegion';
import AWSRegionSelect from '../common/AWSRegionSelect';
import { Truncate } from '../../models/truncate';
import TruncateSelect from '../common/TruncateSelect';
import UserCategorySelect from '../common/UserCategorySelect';
import UserConsentSelect from '../common/UserConsentSelect';
import XLSDownload from '../common/XLSXDownload';
import ZoneSelect from '../common/ZoneSelect';
import ThunkArguments from '../../store/types/common';

const { Text } = Typography;
const { Option } = Select;

interface ErrorReportBarProps {
    fetchErrorReport: (req: FetchErrorReportRequest)
    => (dispatch: Dispatch<ErrorReportAction>,
        getState: () => RootState, args: ThunkArguments) => Promise<void>;
    errorReportStats: ErrorReport[];
    countriesList: Country[];
    providersList: ProviderListItem[];
    groupByList: string[];
    groupByOptions: Record<string, string>;
    onGroupByChange: (checkedValues: CheckboxValueType[]) => void;
}

const dateFormat = 'YYYY-MM-DD';
const statusOK = '200';

const ErrorReportBar: React.FC<ErrorReportBarProps> = props => {
    const { fetchErrorReport, countriesList, providersList, groupByOptions,
        onGroupByChange, errorReportStats, groupByList } = props;

    const getDefaultFrom = () => {
        const date = new Date();
        date.setDate(date.getDate() - 1);
        return formatDate(date.toString());
    };

    const getDefaultTo = () => {
        const date = new Date();
        return formatDate(date.toString());
    };

    const [from, setFrom] = useState(getDefaultFrom());
    const [to, setTo] = useState(getDefaultTo());
    const [country, setCountry] = useState<string | undefined>(undefined);
    const [provider, setProvider] = useState<string | undefined>(undefined);
    const [bundle, setBundle] = useState<string | undefined>(undefined);
    const [checkedValues, setCheckedValues] = useState<CheckboxValueType[]>([]);
    const [isGeneralProvider, setIsGeneralProvider] = useState(false);
    const [tdm, setTDM] = useState<string | undefined>(undefined);
    const [statusCode, setStatusCode] = useState<string | undefined>(undefined);
    const [kindApp, setKindApp] = useState<string | undefined>(undefined);
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [awsRegion, setAWSRegion] = useState<string | undefined>(undefined);
    const [truncate, setTruncate] = useState<string>(Truncate.Daily);
    const [userCategory, setUserCategory] = useState<string | undefined>(undefined);
    const [userConsent, setUserConsent] = useState<string | undefined>(undefined);
    const [zone, setZone] = useState<string | undefined>(undefined);

    const onFromChange: DatePickerProps['onChange'] = (date, dateString) => {
        setFrom(dateString);
    };

    const onToChange: DatePickerProps['onChange'] = (date, dateString) => {
        setTo(dateString);
    };

    const bundleChange = (value: string) => setBundle(value.trimLeft());
    const kindAppChange = (value: string) => setKindApp(value);
    const statusCodeChange = (value: string) => setStatusCode(value);
    const tdmChange = (value: string) => setTDM(value);
    const awsRegionChange = (value: string) => setAWSRegion(value);
    const truncateChange = (value: string) => setTruncate(value);
    const zoneChange = (value: string) => setZone(value);

    const getProvidersList = (): ProviderListItem[] => (
        providersList.concat([{
            id: 0,
            provider_name: 'squid',
            allowed_countries: [],
        }])
    );

    const search = () => {
        setShowErrorMessage(false);
        if (awsRegion !== undefined && !(awsRegion in AWSRegionTypeMap)) {
            setShowErrorMessage(true);
            setErrorMessage('AWS Region is invalid');
            return;
        }
        let groupBy = checkedValues;
        if (truncate) {
            groupBy = truncate === Truncate.Daily
                ? [...checkedValues, 'date'] as CheckboxValueType[]
                : [...checkedValues, truncate].filter(item => item !== 'date') as CheckboxValueType[];
            // check if From date starts with monday for weekly breakdown or with first day for monthly breakdown
            const fromDate = new Date(from);
            if (truncate === Truncate.Weekly && fromDate.getDay() !== 1) {
                setShowErrorMessage(true);
                setErrorMessage('From date should be Monday');
                return;
            } if (truncate === Truncate.Monthly && fromDate.getDate() !== 1) {
                setShowErrorMessage(true);
                setErrorMessage('From date should be the first day of the month');
                return;
            }
        }
        onGroupByChange([...new Set(groupBy)]);
        const req: FetchErrorReportRequest = {
            from,
            to,
            region: awsRegion,
            country_code: country,
            v_provider: provider,
            is_general_provider: isGeneralProvider,
            traffic_distribute_mode: tdm,
            kind_app: kindApp,
            user_category: userCategory,
            user_consent: userConsent,
            zone,
            groupBy: groupBy.map(item => item.toString()),
        };
        if (statusCode) {
            req.is_200 = statusCode === statusOK;
        }
        if (bundle) {
            req.rq_bundle = bundle.trim();
        }
        fetchErrorReport(req);
    };

    const renderGroupBy = () => (
        <Select
            mode="multiple"
            allowClear
            style={{ width: '300px' }}
            onChange={setCheckedValues}
        >
            {[...Object.keys(groupByOptions)]
                .map(key => <Option key={key} value={key}>{groupByOptions[key]}</Option>)}
        </Select>
    );

    return (
        <>
            <Row
                gutter={10}
                justify="start"
                align="middle"
                wrap={false}
                style={{ width: '100%', margin: 'auto', marginBottom: 16 }}
            >
                <Col>
                    <Text>From: </Text>
                    <DatePicker
                        onChange={onFromChange}
                        defaultValue={dayjs(getDefaultFrom(), dateFormat)}
                    />
                </Col>
                <Col>
                    <Text>To: </Text>
                    <DatePicker
                        onChange={onToChange}
                        defaultValue={dayjs(getDefaultTo(), dateFormat)}
                    />
                </Col>
                <Col>
                    <TruncateSelect value={truncate} onChange={truncateChange} />
                </Col>
                <Col>
                    <AWSRegionSelect value={awsRegion} onChange={awsRegionChange} />
                </Col>
                <Col>
                    {providersList.length && <ProviderSelect providers={getProvidersList()} onChange={setProvider} />}
                </Col>
                <Col>
                    <CountrySelect countries={countriesList} onChange={setCountry} />
                </Col>
            </Row>
            <Row
                gutter={10}
                justify="start"
                align="middle"
                wrap={false}
                style={{ width: '100%', margin: 'auto', marginBottom: 16 }}
            >
                <Col>
                    <Text>Bundle:</Text> <BundleSelect onChange={bundleChange} />
                </Col>
                <Col>
                    <Text>API:</Text> <KindAppSelect value={kindApp} onChange={kindAppChange} />
                </Col>
                <Col>
                    <Text>Status code:</Text> <StatusCodeSelect value={statusCode} onChange={statusCodeChange} />
                </Col>
                <Col>
                    <Text>User category:</Text> <UserCategorySelect value={userCategory} onChange={setUserCategory} />
                </Col>
                <Col>
                    <Text>User consent:</Text> <UserConsentSelect value={userConsent} onChange={setUserConsent} />
                </Col>
            </Row>
            <Row
                gutter={10}
                justify="start"
                align="middle"
                wrap={false}
                style={{ width: '100%', margin: 'auto', marginBottom: 16 }}
            >
                <Col>
                    <Text>Zone:</Text> <ZoneSelect value={zone} onChange={zoneChange} />
                </Col>
                <Col>
                    <Text>Traffic distribute mode:</Text> <TDMSelect value={tdm} onChange={tdmChange} />
                </Col>
                <Col>
                    <Text>Is general provider:</Text>  <Checkbox onChange={e => setIsGeneralProvider(e.target.checked)} />
                </Col>
            </Row>
            <Row
                gutter={10}
                justify="start"
                align="middle"
                wrap={false}
                style={{ width: '100%', margin: 'auto', marginBottom: 16 }}
            >
                <Col><Text>Breakdowns:</Text> {renderGroupBy()}</Col>
            </Row>
            <Row
                gutter={10}
                justify="start"
                align="middle"
                wrap={false}
                style={{ width: '100%', margin: 'auto', marginBottom: 16 }}
            >
                <Col>
                    <Button onClick={search} type="primary">Search</Button>
                </Col>
                <Col>
                    {errorReportStats.length > 0 && (
                        <XLSDownload
                            data={errorReportStats}
                            filename="error_report.xlsx"
                            breakdownOptions={Object.keys(groupByOptions)}
                            breakdowns={groupByList}
                        />
                    )}
                </Col>
                <Col>
                    {showErrorMessage && <Text style={{ color: 'red' }}>{errorMessage}</Text>}
                </Col>
            </Row>
        </>
    );
};

export default ErrorReportBar;
