import { useDispatch } from 'react-redux';
import { Space } from 'antd';
import { LogoutOutlined } from '@ant-design/icons';
import styles from './Login.module.scss';
import useTypedSelector from '../../hooks/useTypedSelector';
import useActions from '../../hooks/useActions';

const MenuProfile: React.FC = () => {
    const dispatch = useDispatch();
    const { user } = useTypedSelector(state => state.user);
    const { userLogout } = useActions();

    const getUserEmail = () => {
        const payload = user!.getSignInUserSession()?.getIdToken().decodePayload();
        return payload && payload.email;
    };

    const handleLogout = () => dispatch(userLogout(user!));

    return (
        <div>
            <Space>
                <span style={{ color: 'white' }}>{getUserEmail()}</span>
                <button
                    type="button"
                    title="logout"
                    className={styles.logout__btn}
                    onClick={handleLogout}
                >
                    <LogoutOutlined aria-label="logout" />
                </button>
            </Space>
        </div>
    );
};

export default MenuProfile;
