import React, { useRef } from 'react';
import { Select } from 'antd';
import { BaseSelectRef } from 'rc-select';
import zonesMap from '../../models/zonesMap';

const { Option } = Select;

interface ZoneSelectProps {
    onSelect?: (value: string) => void;
    value?: string;
    onChange?: (value: string) => void;
}

const ZoneSelect: React.FC<ZoneSelectProps> = props => {
    const { onSelect, value, onChange } = props;

    const selectEl = useRef<BaseSelectRef>(null);

    const onZoneSelect = (e: string) => {
        selectEl.current!.blur();
        if (onSelect) {
            onSelect(e);
        }
    };

    const renderOption = (item: [string, string]) => (
        <Option
            key={item[0]}
            value={item[0]}
        >
            {item[1]}
        </Option>
    );

    return (
        <Select
            value={value}
            defaultValue={undefined}
            ref={selectEl}
            style={{ width: 180 }}
            optionFilterProp="children"
            placeholder="Select zone"
            onSelect={onZoneSelect}
            onChange={onChange}
            allowClear
        >
            {[...Object.entries(zonesMap)].map(renderOption)}
        </Select>
    );
};

export default ZoneSelect;
