import { useEffect } from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Typography } from 'antd';
import * as CountriesActionCreators from '../../store/actions/countryAction';
import * as BiddingReportActionCreators from '../../store/actions/biddingReportAction';
import { RootState } from '../../store/reducers';
import { errorNotify } from '../common/Notify';
import BidFloorReportTable from './BidFloorReportTable';
import BidFloorReportBar from './BidFloorReportBar';

const { Title } = Typography;

const mapStateToProps = (state: RootState) => ({
    userState: state.user,
    countryState: state.country,
    biddingReportState: state.biddingReport,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
    const boundActions = bindActionCreators({
        ...CountriesActionCreators,
        ...BiddingReportActionCreators,
    }, dispatch);
    return {
        ...boundActions,
    };
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type ProviderProps = StateProps & DispatchProps;

const BidFloorReport: React.FC<ProviderProps> = props => {
    const { countryState, biddingReportState } = props;
    const { countries, error: errorCountry } = countryState;
    const { bidFloorStats, error: errorBiddingReport, loading } = biddingReportState;
    const { fetchCountries, fetchBidFloorReport } = props;

    useEffect(() => {
        fetchCountries();
    }, []);

    useEffect(() => {
        if (errorCountry) errorNotify('Country error', errorCountry);
    }, [errorCountry]);

    useEffect(() => {
        if (errorBiddingReport) errorNotify('BidFloor report error', errorBiddingReport);
    }, [errorBiddingReport]);

    return (
        <>
            <Title level={2} style={{ marginTop: 0 }}>BidFloor Report</Title>
            <BidFloorReportBar
                fetchBidFloorReport={fetchBidFloorReport}
                countries={countries}
            />
            <BidFloorReportTable
                loading={loading}
                error={errorBiddingReport}
                bidFloorStats={bidFloorStats}
            />
        </>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(BidFloorReport);
