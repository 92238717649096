import { Button, Tooltip } from 'antd';
import * as XLSX from 'xlsx';
import { CloudDownloadOutlined } from '@ant-design/icons';

interface XLSDownloadProps {
    data: any[];
    filename: string;
    breakdownOptions?: string[];
    breakdowns?: string[];
}

const XLSDownload: React.FC<XLSDownloadProps> = props => {
    const { data, filename, breakdownOptions, breakdowns } = props;

    const getHeaders = (): string[] => {
        const allHeaders = Object.keys(data[0]);
        if (!breakdownOptions || breakdownOptions.length === 0) {
            return allHeaders;
        }
        if (!breakdowns || breakdowns.length === 0) {
            return allHeaders;
        }
        const toFilter = breakdownOptions.filter(breakdown => !breakdowns.includes(breakdown));
        return allHeaders.filter(header => !toFilter.includes(header));
    };

    const getContent = (headers: string[]): string[][] => {
        const result: string[][] = [];
        data.forEach(datum => result.push(headers.map(header => datum[header]?.toString())));
        return result;
    };

    const downloadXLS = () => {
        if (data.length === 0) return;
        const headers = getHeaders();
        const content = getContent(headers);
        const aoa: string[][] = [headers, ...content];

        const ws = XLSX.utils.aoa_to_sheet(aoa);
        const wb = XLSX.utils.book_new();

        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
        XLSX.writeFileXLSX(wb, filename);
    };

    return (
        <Tooltip title="Download XLS">
            <Button onClick={downloadXLS} icon={<CloudDownloadOutlined />} />
        </Tooltip>
    );
};

export default XLSDownload;
