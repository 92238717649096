import { useEffect } from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Typography } from 'antd';
import * as CountriesActionCreators from '../../store/actions/countryAction';
import * as AdblockReportActionCreators from '../../store/actions/adblockReportAction';
import { RootState } from '../../store/reducers';
import { errorNotify } from '../common/Notify';
import AdblockReportBar from './AdblockReportBar';
import AdblockReportTable from './AdblockReportTable';
import * as ProviderActionCreators from '../../store/actions/providerAction';

const { Title } = Typography;

const mapStateToProps = (state: RootState) => ({
    userState: state.user,
    countryState: state.country,
    providerState: state.provider,
    adblockReportState: state.adblockReport,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
    const boundActions = bindActionCreators({
        ...CountriesActionCreators,
        ...ProviderActionCreators,
        ...AdblockReportActionCreators,
    }, dispatch);
    return {
        ...boundActions,
    };
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type AdblockReportProps = StateProps & DispatchProps;

const AdblockReport: React.FC<AdblockReportProps> = props => {
    const { userState, countryState, providerState, adblockReportState } = props;
    const { groups } = userState;
    const { countries, error: errorCountry } = countryState;
    const { providersList, error: errorProvider } = providerState;
    const { adblockReportStats, error: errorAdblockReport, loading } = adblockReportState;
    const { fetchCountries, fetchProvidersList, fetchAdblockReport, unblockCrID } = props;

    useEffect(() => {
        fetchCountries();
        fetchProvidersList();
    }, []);

    useEffect(() => {
        if (errorCountry) errorNotify('Country error', errorCountry);
    }, [errorCountry]);
    useEffect(() => {
        if (errorProvider) errorNotify('Provider error', errorProvider);
    }, [errorProvider]);
    useEffect(() => {
        if (errorAdblockReport) errorNotify('Adblock report error', errorAdblockReport);
    }, [errorAdblockReport]);

    return (
        <>
            <Title level={2} style={{ marginTop: 0 }}>Adblock Report</Title>
            <AdblockReportBar
                fetchAdblockReport={fetchAdblockReport}
                providersList={providersList}
                countriesList={countries}
            />
            <AdblockReportTable
                loading={loading}
                error={errorAdblockReport}
                adblockReportStats={adblockReportStats}
                groups={groups}
                unblockCrID={unblockCrID}
            />
        </>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(AdblockReport);
