interface Provider {
    country: string;
    updated_at: string;
    posts: PostData[];
    counts: number;
}

interface ProviderListItem {
    id: number;
    provider_name: ProviderName;
    allowed_countries: string[];
}

interface PostData {
    post_id: string;
    title: string;
    created_at: string;
    updated_at: string;
}

type ProviderName = 'affiliate' | 'nativex' | 'mgid' | 'outbrain' | 'plista' | 'prebid' | 'strossle' | 'squid';

const sortProviders = (a: ProviderListItem, b: ProviderListItem) => a.provider_name.localeCompare(b.provider_name);

enum ContentProvider {
    Squid = 'Squid',
    Upday = 'Upday',
}

export type {
    Provider,
    ProviderListItem,
    PostData,
    ProviderName,
};

export {
    sortProviders,
    ContentProvider,
};
