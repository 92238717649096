/**
 * compareValues compares two values
 * @param a {string | number} first object
 * @param b {string | number} second object
 * @returns {number} -1: sort 'a' after 'b', 1: sort 'b' after 'a', 0: keep original order
 */
const compareValues = (a: string | number, b: string | number): number => {
    if ((a !== undefined && b === undefined) || (a < b)) {
        return 1;
    }
    if ((a === undefined && b !== undefined) || (a > b)) {
        return -1;
    }
    return 0;
};

export {
    // eslint-disable-next-line
    compareValues,
};
