import React, { useRef } from 'react';
import { AutoComplete } from 'antd';
import { BaseSelectRef } from 'rc-select';

interface BundleSelectProps {
    onSelect?: (value: string) => void;
    onChange?: (value: string) => void;
}

const BundleSelect: React.FC<BundleSelectProps> = props => {
    const { onSelect, onChange } = props;
    const options = [
        { label: 'Assistant', value: 'com.huawei.intelligent' },
        { label: 'Browser', value: 'com.huawei.browser' },
        { label: 'Petal', value: 'com.huawei.hwsearch' },
        { label: 'AI Search Banner', value: 'com.huawei.search.banner' },
        { label: 'AI Search Newsfeed', value: 'com.huawei.search.newsfeed' },
        { label: 'Third Party Apps', value: 'third_party_apps' },
    ];

    const selectEl = useRef<BaseSelectRef>(null);

    const onBundleSelect = (e: string) => {
        selectEl.current!.blur();
        if (onSelect) {
            onSelect(e);
        }
    };

    return (
        <AutoComplete
            style={{ width: 150 }}
            defaultValue={undefined}
            ref={selectEl}
            options={options}
            placeholder="Select or type bundle name"
            onChange={onChange}
            onSelect={onBundleSelect}
            filterOption={(input, option) => option!.value.toUpperCase().indexOf(input.toUpperCase()) !== -1}
            allowClear
        />
    );
};

export default BundleSelect;
