import { useState } from 'react';
import { Spin } from 'antd';
import styles from './WorkRequest.module.scss';

const WorkRequest: React.FC = () => {
    const [loading, setLoading] = useState(true);

    const hideLoading = () => setLoading(false);

    return (
        <div className={styles.wrapper}>
            {loading ? (
                <Spin />
            ) : null}
            <iframe
                src="https://forms.monday.com/forms/embed/4ea090629fb77270604a3a2780cf754c?r=use1"
                onLoad={hideLoading}
                title="monday"
                width="1650"
                height="1400"
                style={{ border: 0, backgroundColor: '#eef0f3' }}
            />
        </div>
    );
};

export default WorkRequest;
