import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { LinkOutlined } from '@ant-design/icons';
import { getCountryFullName } from '../../models/country';
import { withKeys } from '../../helpers/array';
import { compareDates, formatDate } from '../../helpers/date';
import { compareValues } from '../../helpers/compare';
import { AdblockReportStat } from '../../models/adblock';
import { DeleteAction } from '../../store/types/actions';
import { isAdblockDeleter, isAdmin, UserGroups } from '../../models/user';
import AdblockReportActions from './AdblockReportActions';

interface adblockReportColumn {
    key: string;
    date: string;
    cr_id: string;
    country?: string;
    provider?: string;
    image: string;
    title: string;
    link: string;
    block_reason: string;
    total: number;
}

interface AdblockReportTableProps {
    loading: boolean;
    error: null | string;
    adblockReportStats: AdblockReportStat[];
    groups: UserGroups[];
    unblockCrID: DeleteAction;
}

const AdblockReportTable: React.FC<AdblockReportTableProps> = props => {
    const { loading, adblockReportStats, groups, unblockCrID } = props;
    const defaultPageSize = 30;

    const isAllowedUser = (): boolean => isAdmin(groups) ? true : isAdblockDeleter(groups);

    const fixUrl = (url: string): string => {
        try {
            const _ = new URL(url);
        } catch {
            return `https://${url}`;
        }
        return url;
    };

    const getColumns = (): ColumnsType<adblockReportColumn> => {
        const columns: ColumnsType<adblockReportColumn> = [
            {
                title: 'Date',
                dataIndex: 'date',
                key: 'date',
                align: 'right',
                width: '10%',
                sorter: (a, b) => compareDates(a.date || '', b.date || ''),
                render: date => <span style={{ whiteSpace: 'nowrap' }}>{formatDate(date)}</span>,
            },
            {
                title: 'Creative ID',
                dataIndex: 'cr_id',
                key: 'cr_id',
                align: 'right',
            },
            {
                title: 'Country Name',
                dataIndex: 'country',
                key: 'country',
                align: 'left',
                width: '10%',
                sorter: (a, b) => compareValues(a.country!, b.country!),
                render: (country: string) => getCountryFullName(country),
            },
            {
                title: 'Provider',
                dataIndex: 'provider',
                key: 'provider',
                align: 'left',
                width: '10%',
                sorter: (a, b) => compareValues(a.provider!, b.provider!),
            },
            {
                title: 'Title',
                dataIndex: 'title',
                key: 'title',
                align: 'left',
                width: '20%',
                sorter: (a, b) => a.title.localeCompare(b.title),
            },
            {
                title: 'Image',
                dataIndex: 'image',
                key: 'image',
                align: 'left',
                render: image => <a rel="noreferrer" target="_blank" href={fixUrl(image)}>Image link <LinkOutlined /></a>,
            },
            {
                title: 'Link',
                dataIndex: 'link',
                key: 'link',
                align: 'left',
                render: link => link ? <a rel="noreferrer" target="_blank" href={link}>Link <LinkOutlined /></a> : '',
            },
            {
                title: 'Block Reason',
                dataIndex: 'block_reason',
                key: 'block_reason',
                align: 'left',
                width: '20%',
                sorter: (a, b) => compareValues(a.block_reason, b.block_reason),
            },
            {
                title: 'Total',
                dataIndex: 'total',
                key: 'total',
                align: 'left',
                defaultSortOrder: 'descend',
                sorter: (a, b) => a.total - b.total,
                render: total => total.toLocaleString(),
            },
            {
                title: 'Action',
                key: 'action',
                align: 'center',
                width: '10%',
                render: record => isAllowedUser()
                    ? (
                        <AdblockReportActions
                            adblocked={record}
                            unblockCrID={unblockCrID}
                        />
                    ) : null,
            },
        ];
        return columns;
    };

    return (
        <Table
            className="adblockreport__table"
            style={{ width: '100%', margin: 'auto' }}
            bordered
            loading={loading}
            rowKey={record => record.key}
            dataSource={withKeys(adblockReportStats)}
            columns={getColumns()}
            pagination={{ defaultPageSize }}
            tableLayout="auto"
            size="small"
            scroll={{ y: '60vh' }}
        />
    );
};

export default AdblockReportTable;
