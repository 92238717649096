import { LURLReason, LURLStat } from '../../models/lurl';

export interface LURLReportState {
    lurlStats: LURLStat[];
    lurlReasons: LURLReason[];
    loading: boolean;
    error: null | string;
}

export enum LURLReportActionTypes {
    FETCH_LURL_REPORT_REQUEST = 'FETCH_LURL_REPORT_REQUEST',
    FETCH_LURL_REPORT_SUCCESS = 'FETCH_LURL_REPORT_SUCCESS',
    FETCH_LURL_REPORT_ERROR = 'FETCH_LURL_REPORT_ERROR',
    FETCH_LURL_REASONS_REQUEST = 'FETCH_LURL_REASONS_REQUEST',
    FETCH_LURL_REASONS_SUCCESS = 'FETCH_LURL_REASONS_SUCCESS',
    FETCH_LURL_REASONS_ERROR = 'FETCH_LURL_REASONS_ERROR',
}

interface FetchLURLReportRequestAction {
    type: LURLReportActionTypes.FETCH_LURL_REPORT_REQUEST;
}

interface FetchLURLReportSuccessAction {
    type: LURLReportActionTypes.FETCH_LURL_REPORT_SUCCESS;
    payload: LURLStat[];
}

interface FetchLURLReportErrorAction {
    type: LURLReportActionTypes.FETCH_LURL_REPORT_ERROR;
    payload: string;
}

interface FetchLURLReasonsRequestAction {
    type: LURLReportActionTypes.FETCH_LURL_REASONS_REQUEST;
}

interface FetchLURLReasonsSuccessAction {
    type: LURLReportActionTypes.FETCH_LURL_REASONS_SUCCESS;
    payload: LURLReason[];
}

interface FetchLURLReasonsErrorAction {
    type: LURLReportActionTypes.FETCH_LURL_REASONS_ERROR;
    payload: string;
}

export type LURLReportAction = FetchLURLReportRequestAction |
    FetchLURLReportSuccessAction |
    FetchLURLReportErrorAction |
    FetchLURLReasonsRequestAction |
    FetchLURLReasonsSuccessAction |
    FetchLURLReasonsErrorAction;
