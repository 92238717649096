import { CountryPrice } from './price';
import { ProviderName } from './provider';

interface Bundle {
    id?: number;
    name: string;
    url: string;
    gp_url: string;
    price: number;
    price_by_country?: CountryPrice[];
    updated_at?: string;
    updated_by: string;
}

export enum KindApp {
    Main = 'main',
    DSP = 'dsp',
}

export interface BundleWeight {
    id?: number;
    bundle: string;
    country: string;
    weights: Record<string, number>;
    created_at?: string;
    updated_at?: string;
    updated_by: string;
}

export default Bundle;
