import { useState } from 'react';
import { Space, Button } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import EditWeightModal from '../modals/EditWeightModal';
import { ProviderListItem } from '../../models/provider';
import { Weight, WeightKind } from '../../models/country';
import { UpdateAction } from '../../store/types/actions';

interface WeightMapActionsProps {
    countryName: string;
    weight: Weight;
    error: null | string;
    providersList: ProviderListItem[];
    updateWeight: UpdateAction<Weight, void>;
    kind: WeightKind;
}

const WeightMapActions: React.FC<WeightMapActionsProps> = props => {
    const { countryName, weight, error, providersList, updateWeight, kind } = props;
    const [showUpdateModal, setShowUpdateModal] = useState(false);

    return (
        <Space size="small">
            <Button size="small" icon={<EditOutlined />} onClick={() => setShowUpdateModal(true)} />
            <EditWeightModal
                countryName={countryName}
                weight={weight}
                open={showUpdateModal}
                setOpen={setShowUpdateModal}
                updateWeight={updateWeight}
                providersList={providersList}
                kind={kind}
                error={error}
            />
        </Space>
    );
};

export default WeightMapActions;
