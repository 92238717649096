import React, { useRef } from 'react';
import { Select } from 'antd';
import { BaseSelectRef } from 'rc-select';
import { UserConsentMap } from '../../models/errorReport';

const { Option } = Select;

interface UserConsentSelectProps {
    onSelect?: (value: string) => void;
    value?: string;
    onChange?: (value: string) => void;
}

const UserConsentSelect: React.FC<UserConsentSelectProps> = props => {
    const { onSelect, value, onChange } = props;

    const selectEl = useRef<BaseSelectRef>(null);

    const onUserConsentSelect = (e: string) => {
        selectEl.current!.blur();
        if (onSelect) {
            onSelect(e);
        }
    };

    const renderOption = (item: string, index: number) => (
        <Option
            key={item}
            value={index}
        >
            {item}
        </Option>
    );

    return (
        <Select
            value={value}
            defaultValue={undefined}
            ref={selectEl}
            style={{ width: 120 }}
            optionFilterProp="children"
            placeholder="Select user consent"
            onSelect={onUserConsentSelect}
            onChange={onChange}
            allowClear
        >
            {[...Object.values(UserConsentMap)].map(renderOption)}
        </Select>
    );
};

export default UserConsentSelect;
