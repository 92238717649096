import { AxiosError } from 'axios';
import queryString from 'query-string';
import { authInterceptor } from './interceptors';
import { LURLReportAction, LURLReportActionTypes } from '../types/lurl';
import { handleError } from '../../helpers/error';
import { FetchLURLReportRequest, LURLStats, LURLReason } from '../../models/lurl';
import { Action } from '../types/actions';

export const fetchLURLReport = (req: FetchLURLReportRequest): Action<LURLReportAction> => {
    const { from, to, bundle, country, zone, groupBy, tdm, provider, lurlReason } = req;
    const query = queryString.stringify({
        from,
        to,
        bundle,
        traffic_distribute_mode: tdm,
        country,
        provider,
        zone,
        lurl_reason: lurlReason,
        group_by: groupBy,
    }, { arrayFormat: 'comma' });

    return async (dispatch, getState, { reportsAPI }) => {
        dispatch({ type: LURLReportActionTypes.FETCH_LURL_REPORT_REQUEST });
        reportsAPI.interceptors.request.use(authInterceptor(getState().user.jwtToken));

        try {
            const res = await reportsAPI.get<LURLStats>(`reports/lurl?${query}`);
            dispatch({ type: LURLReportActionTypes.FETCH_LURL_REPORT_SUCCESS, payload: res.data.stats });
        } catch (e: any | AxiosError) {
            handleError(dispatch, e, LURLReportActionTypes.FETCH_LURL_REPORT_ERROR, 'Error fetching LURL report');
        }
    };
};

export const fetchLURLReasons = (): Action<LURLReportAction> => (
    async (dispatch, getState, { reportsAPI }) => {
        dispatch({ type: LURLReportActionTypes.FETCH_LURL_REASONS_REQUEST });
        reportsAPI.interceptors.request.use(authInterceptor(getState().user.jwtToken));

        try {
            const res = await reportsAPI.get<LURLReason[]>('lurl-reasons');
            dispatch({ type: LURLReportActionTypes.FETCH_LURL_REASONS_SUCCESS, payload: res.data });
        } catch (e: any | AxiosError) {
            handleError(dispatch, e, LURLReportActionTypes.FETCH_LURL_REASONS_ERROR, 'Error fetching LURL reasons');
        }
    }
);
