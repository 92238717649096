import { useState } from 'react';
import { Dispatch } from 'redux';
import { Button, DatePicker, DatePickerProps, Typography, Row, Col } from 'antd';
import dayjs from 'dayjs';
import { RootState } from '../../store/reducers';
import { formatDate } from '../../helpers/date';
import { FetchAdblockReportRequest } from '../../models/adblock';
import { AdblockAction } from '../../store/types/adblock';
import { ProviderListItem } from '../../models/provider';
import { Country } from '../../models/country';
import ProviderSelect from '../common/ProviderSelect';
import CountrySelect from '../common/CountrySelect';
import { Action } from '../../store/types/actions';

const { Text } = Typography;

interface AdblockBarProps {
    fetchAdblockReport: (req: FetchAdblockReportRequest) => Action<AdblockAction>;
    providersList: ProviderListItem[];
    countriesList: Country[];
}

const dateFormat = 'YYYY-MM-DD';
const maxTimeDifference = 7776000000;

const AdblockReportBar: React.FC<AdblockBarProps> = props => {
    const { fetchAdblockReport, countriesList, providersList } = props;

    const getDefaultFrom = () => {
        const date = new Date();
        date.setDate(date.getDate() - 1);
        return formatDate(date.toString());
    };

    const getDefaultTo = () => {
        const date = new Date();
        return formatDate(date.toString());
    };

    const [from, setFrom] = useState(getDefaultFrom());
    const [to, setTo] = useState(getDefaultTo());
    const [country, setCountry] = useState<string | undefined>(undefined);
    const [provider, setProvider] = useState<string | undefined>(undefined);
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const onFromChange: DatePickerProps['onChange'] = (date, dateString) => {
        setFrom(dateString);
    };

    const onToChange: DatePickerProps['onChange'] = (date, dateString) => {
        setTo(dateString);
    };

    const search = () => {
        setShowErrorMessage(false);
        const fromTime = new Date(from).getTime();
        const toTime = new Date(to).getTime();
        const diff = toTime - fromTime;
        if (diff > maxTimeDifference) {
            setShowErrorMessage(true);
            setErrorMessage('Time range must be lower than 90 days!');
            return;
        }

        const req: FetchAdblockReportRequest = {
            from,
            to,
            country,
            provider,
        };

        fetchAdblockReport(req);
    };

    return (
        <>
            <Row gutter={10} justify="start" align="middle" wrap={false} style={{ width: '100%', margin: 'auto', marginBottom: 16 }}>
                <Col style={{ whiteSpace: 'nowrap' }}>
                    <Text>From: </Text><DatePicker onChange={onFromChange} defaultValue={dayjs(getDefaultFrom(), dateFormat)} />
                </Col>
                <Col style={{ whiteSpace: 'nowrap' }}>
                    <Text>To: </Text><DatePicker onChange={onToChange} defaultValue={dayjs(getDefaultTo(), dateFormat)} />
                </Col>
                <Col>
                    <CountrySelect countries={countriesList} onChange={setCountry} />
                </Col>
                <Col>
                    {providersList.length && <ProviderSelect providers={providersList} onChange={setProvider} />}
                </Col>
                <Col>
                    {showErrorMessage && <Text style={{ color: 'red' }}>{errorMessage}</Text>}
                </Col>
            </Row>
            <Row gutter={10} justify="start" align="middle" wrap={false} style={{ width: '100%', margin: 'auto', marginBottom: 16 }}>
                <Col>
                    <Button onClick={search} type="primary">Search</Button>
                </Col>
            </Row>
        </>
    );
};

export default AdblockReportBar;
