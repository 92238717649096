export interface LURLStat {
    date: string;
    reason: string;
    tdm: string;
    error_reason: string;
    total: number;
    country: string;
    zone: string;
    provider: string;
}

export interface LURLStats {
    stats: LURLStat[];
}

export interface FetchLURLReportRequest {
    from: string,
    to: string,
    groupBy: string[],
    bundle?: string,
    country?: string,
    zone?: string,
    tdm?: string,
    provider?: string,
    lurlReason?: string,
}

export interface LURLReason {
    reason: string,
    error_reason: string,
}

export const sortReasons = (a: LURLReason, b: LURLReason) => parseInt(a.reason, 10) - parseInt(b.reason, 10);
