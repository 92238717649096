import axios from 'axios';
import { AnyAction, Dispatch } from 'redux';

const handleError = (dispatch: Dispatch<AnyAction>, e: any, type: string, msg: string) => {
    const payload = axios.isAxiosError(e) ? `${msg}: ${e.response?.data.message}` : `${msg}: ${e.message}`;
    dispatch({ type, payload });
};

export {
    // eslint-disable-next-line
    handleError,
};
