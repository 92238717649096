import { PerformanceReportState, PerformanceReportAction, PerformanceReportActionTypes } from '../types/performance';

const defaultState: PerformanceReportState = {
    performanceStats: [],
    loading: false,
    error: null,
};

const performanceReportReducer = (state = defaultState, action: PerformanceReportAction): PerformanceReportState => {
    switch (action.type) {
    case PerformanceReportActionTypes.FETCH_PERFORMANCE_REPORT_REQUEST:
        return { ...state, loading: true, error: null };
    case PerformanceReportActionTypes.FETCH_PERFORMANCE_REPORT_SUCCESS:
        return { ...state, performanceStats: action.payload, loading: false, error: null };
    case PerformanceReportActionTypes.FETCH_PERFORMANCE_REPORT_ERROR:
        return { ...state, error: action.payload, loading: false };
    default:
        return state;
    }
};

export default performanceReportReducer;
