import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { getCountryFullName, FactWeight } from '../../models/country';
import { ProviderListItem } from '../../models/provider';
import { compareDates, formatDate } from '../../helpers/date';

interface factWeightMapColumn {
    country: string;
    countryCode: string;
}

interface FactWeightMapTableProps {
    loading: boolean;
    error: null | string;
    factWeights: FactWeight[];
    providersList: ProviderListItem[];
}

const FactWeightMapTable: React.FC<FactWeightMapTableProps> = props => {
    const { loading, factWeights, providersList } = props;

    const getFactWeightMap = (): factWeightMapColumn[] => (
        factWeights.map(weight => {
            const providerWeights = weight.weights;
            const { date, country } = weight;
            return {
                country: getCountryFullName(country),
                countryCode: country,
                ...providerWeights,
            };
        })
    );

    const getProvidersColumns = (): ColumnsType<factWeightMapColumn> => (
        providersList.map(({ provider_name }) => ({
            title: provider_name,
            dataIndex: provider_name,
            key: provider_name,
            align: 'right',
            render: providerName => providerName ? `${providerName}%` : '',
        }
        ))
    );

    const columns: ColumnsType<factWeightMapColumn> = [
        {
            title: 'Country Name',
            dataIndex: 'country',
            key: 'country',
            align: 'left',
            fixed: 'left',
            sorter: (a, b) => a.country.localeCompare(b.country),
            defaultSortOrder: 'ascend',
        },
        ...getProvidersColumns(),
    ];

    return (
        <Table
            className="factweightmap__table"
            style={{ width: '100%', margin: 'auto' }}
            bordered
            loading={loading}
            rowKey={record => record.country}
            dataSource={getFactWeightMap()}
            columns={columns}
            pagination={false}
            size="small"
            scroll={{ y: '64vh' }}
        />
    );
};

export default FactWeightMapTable;
