/**
 * Capitalize first letter of the word
 * @param input string word
 * @returns capitalized
 */

const capitalize = (input: string) => input.charAt(0).toUpperCase() + input.slice(1);

export {
    // eslint-disable-next-line
    capitalize,
};
