import { CountryAction, CountryActionTypes, CountryState } from '../types/country';

const defaultState: CountryState = {
    countries: [],
    weights: [],
    factWeights: [],
    loading: false,
    error: null,
};

const countryReducer = (state = defaultState, action: CountryAction): CountryState => {
    switch (action.type) {
    case CountryActionTypes.FETCH_COUNTRIES_REQUEST:
        return { ...state, loading: true, error: null };
    case CountryActionTypes.FETCH_COUNTRIES_SUCCESS:
        return { ...state, countries: action.payload, loading: false, error: null };
    case CountryActionTypes.FETCH_COUNTRIES_ERROR:
        return { ...state, error: action.payload, loading: false };
    case CountryActionTypes.FETCH_WEIGHTS_REQUEST:
        return { ...state, loading: true, error: null };
    case CountryActionTypes.FETCH_WEIGHTS_SUCCESS:
        return { ...state, weights: action.payload, loading: false, error: null };
    case CountryActionTypes.FETCH_WEIGHTS_ERROR:
        return { ...state, error: action.payload, loading: false };
    case CountryActionTypes.UPDATE_WEIGHT_REQUEST:
        return { ...state, loading: true, error: null };
    case CountryActionTypes.UPDATE_WEIGHT_SUCCESS: {
        const updateIdx = state.weights.findIndex(weight => weight.country === action.payload.country);
        const weights = [...state.weights];
        weights[updateIdx] = action.payload;
        return { ...state, weights, loading: false, error: null };
    }
    case CountryActionTypes.UPDATE_WEIGHT_ERROR:
        return { ...state, error: action.payload, loading: false };
    case CountryActionTypes.ADD_COUNTRY_REQUEST:
        return { ...state, loading: true, error: null };
    case CountryActionTypes.ADD_COUNTRY_SUCCESS: {
        const weights = [...state.weights, action.payload];
        return { ...state, weights, loading: false, error: null };
    }
    case CountryActionTypes.ADD_COUNTRY_ERROR:
        return { ...state, error: action.payload, loading: false };
    case CountryActionTypes.FETCH_FACT_WEIGHTS_REQUEST:
        return { ...state, loading: true, error: null };
    case CountryActionTypes.FETCH_FACT_WEIGHTS_SUCCESS:
        return { ...state, factWeights: action.payload, loading: false, error: null };
    case CountryActionTypes.FETCH_FACT_WEIGHTS_ERROR:
        return { ...state, error: action.payload, loading: false };
    default:
        return state;
    }
};

export default countryReducer;
