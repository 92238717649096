import { AxiosError } from 'axios';
import queryString from 'query-string';
import { authInterceptor } from './interceptors';
import { IncomingReportAction, IncomingReportActionTypes } from '../types/incoming';
import { IncomingInfoStats } from '../../models/incoming';
import { handleError } from '../../helpers/error';
import { Action } from '../types/actions';

// eslint-disable-next-line
export const fetchIncomingReport = (
    from: string,
    to: string,
    cr_id: string,
    country_code?: string,
    provider?: string,
    rp_title?: string,
): Action<IncomingReportAction> => {
    const query = queryString.stringify({
        from, to, cr_id, country_code, provider, rp_title }, { arrayFormat: 'comma' });

    return async (dispatch, getState, { reportsAPI }) => {
        dispatch({ type: IncomingReportActionTypes.FETCH_INCOMING_REPORT_REQUEST });
        reportsAPI.interceptors.request.use(authInterceptor(getState().user.jwtToken));

        try {
            const res = await reportsAPI.get<IncomingInfoStats>(`reports/incoming?${query}`);
            dispatch({ type: IncomingReportActionTypes.FETCH_INCOMING_REPORT_SUCCESS, payload: res.data.stats });
        } catch (e: any | AxiosError) {
            handleError(
                dispatch,
                e,
                IncomingReportActionTypes.FETCH_INCOMING_REPORT_ERROR,
                'Error fetching incoming report',
            );
        }
    };
};
