import { ErrorReport } from '../../models/errorReport';

export interface ErrorReportState {
    errorReportStats: ErrorReport[];
    loading: boolean;
    error: null | string;
}

export enum ErrorReportActionTypes {
    FETCH_ERROR_REPORT_REQUEST = 'FETCH_ERROR_REPORT_REQUEST',
    FETCH_ERROR_REPORT_SUCCESS = 'FETCH_ERROR_REPORT_SUCCESS',
    FETCH_ERROR_REPORT_ERROR = 'FETCH_ERROR_REPORT_ERROR',
}

interface FetchErrorReportRequestAction {
    type: ErrorReportActionTypes.FETCH_ERROR_REPORT_REQUEST;
}

interface FetchErrorReportSuccessAction {
    type: ErrorReportActionTypes.FETCH_ERROR_REPORT_SUCCESS;
    payload: ErrorReport[];
}

interface FetchErrorReportErrorAction {
    type: ErrorReportActionTypes.FETCH_ERROR_REPORT_ERROR;
    payload: string;
}

export type ErrorReportAction = FetchErrorReportRequestAction |
    FetchErrorReportSuccessAction |
    FetchErrorReportErrorAction;
