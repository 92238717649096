import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { Country, getCountryFullName, WeightKindMap } from '../../models/country';
import { UserGroups } from '../../models/user';
import { compareDates, formatDate } from '../../helpers/date';
import { LURLStat } from '../../models/lurl';
import { withKeys } from '../../helpers/array';

interface lurlReportColumn {
    key: string;
    date: string;
    tdm: string;
    reason: string;
    error_reason: string;
    total: number;
    country: string;
    zone: string;
    provider: string;
}

interface LURLReportTableProps {
    loading: boolean;
    error: null | string;
    lurlStats: LURLStat[];
    countries: Country[];
    groups: UserGroups[];
    groupByList: string[];
}

const LURLReportTable: React.FC<LURLReportTableProps> = props => {
    const { loading, lurlStats, groupByList } = props;
    const defaultPageSize = 30;

    const getColumns = (): ColumnsType<lurlReportColumn> => {
        const groupColumns: ColumnsType<lurlReportColumn> = [
            {
                title: 'Country Name',
                dataIndex: 'country',
                key: 'country',
                align: 'left',
                sorter: (a, b) => a.country.localeCompare(b.country),
                render: (country: string) => getCountryFullName(country),
            },
            {
                title: 'Provider',
                dataIndex: 'provider',
                key: 'provider',
                align: 'right',
                sorter: (a, b) => a.provider.localeCompare(b.provider),
            },
            {
                title: 'Traffic distribute mode',
                dataIndex: 'tdm',
                key: 'tdm',
                align: 'left',
                sorter: (a, b) => a.tdm.toString().localeCompare(b.tdm.toString()),
                render: (tdm: string): string => WeightKindMap[tdm],
            },
            {
                title: 'Zone',
                dataIndex: 'zone',
                key: 'zone',
                align: 'left',
                sorter: (a, b) => a.zone.localeCompare(b.zone),
            },
        ];
        const filtered = groupColumns.filter(item => groupByList.includes(item.key!.toString()));
        const columns: ColumnsType<lurlReportColumn> = [
            {
                title: 'Date',
                dataIndex: 'date',
                key: 'date',
                align: 'right',
                sorter: (a, b) => compareDates(a.date || '', b.date || ''),
                defaultSortOrder: 'ascend',
                render: date => <span>{formatDate(date)}</span>,
            },
            ...filtered,
            {
                title: 'Reason',
                dataIndex: 'reason',
                key: 'reason',
                align: 'right',
            },
            {
                title: 'Error reason',
                dataIndex: 'error_reason',
                key: 'error_reason',
                align: 'right',
                sorter: (a, b) => a.error_reason.toString().localeCompare(b.error_reason.toString()),
            },
            {
                title: 'Total',
                dataIndex: 'total',
                key: 'total',
                align: 'right',
                sorter: (a, b) => a.total - b.total,
            },
        ];
        return columns;
    };

    return (
        <Table
            className="lurlreport__table"
            style={{ width: '80%', margin: 'auto' }}
            bordered
            loading={loading}
            rowKey={record => record.key}
            dataSource={withKeys(lurlStats)}
            columns={getColumns()}
            pagination={{ defaultPageSize }}
        />
    );
};

export default LURLReportTable;
