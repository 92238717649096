import { ProviderAction, ProviderActionTypes, ProviderState } from '../types/provider';

const defaultState: ProviderState = {
    providers: [],
    providersList: [],
    loading: false,
    error: null,
};

const providerReducer = (state = defaultState, action: ProviderAction): ProviderState => {
    switch (action.type) {
    case ProviderActionTypes.FETCH_PROVIDER_REQUEST:
        return { ...state, loading: true, error: null };
    case ProviderActionTypes.FETCH_PROVIDER_SUCCESS:
        return { ...state, providers: action.payload, loading: false, error: null };
    case ProviderActionTypes.FETCH_PROVIDER_ERROR:
        return { ...state, error: action.payload, loading: false };
    case ProviderActionTypes.FETCH_PROVIDERS_LIST_REQUEST:
        return { ...state, loading: true, error: null };
    case ProviderActionTypes.FETCH_PROVIDERS_LIST_SUCCESS:
        return { ...state, providersList: action.payload, loading: false, error: null };
    case ProviderActionTypes.FETCH_PROVIDERS_LIST_ERROR:
        return { ...state, error: action.payload, loading: false };
    default:
        return state;
    }
};

export default providerReducer;
