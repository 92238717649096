import { useEffect, useState } from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import dayjs from 'dayjs';
import { Button, DatePicker, DatePickerProps, Typography, Row, Col } from 'antd';
import * as ProviderActionCreators from '../../store/actions/providerAction';
import * as CountriesActionCreators from '../../store/actions/countryAction';
import { RootState } from '../../store/reducers';
import { errorNotify } from '../common/Notify';
import { WeightKind } from '../../models/country';
import { formatDate } from '../../helpers/date';
import FactWeightMapTable from './FactWeightMapTable';

const { Title, Text } = Typography;
const dateFormat = 'YYYY-MM-DD';

const mapStateToProps = (state: RootState) => ({
    providerState: state.provider,
    countryState: state.country,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
    const boundActions = bindActionCreators({
        ...ProviderActionCreators,
        ...CountriesActionCreators,
    }, dispatch);
    return {
        ...boundActions,
    };
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type ProviderProps = StateProps & DispatchProps & {
    kind: WeightKind;
};

const FactWeightMap: React.FC<ProviderProps> = props => {
    const { providerState, countryState, kind } = props;
    const { providersList, error: errorProvider, loading: loadingProvider } = providerState;
    const { factWeights, error: errorCountry } = countryState;
    const { fetchFactWeights, fetchProvidersList } = props;

    const getDefaultDate = () => {
        const date = new Date();
        date.setDate(date.getDate() - 1);
        return formatDate(date.toString());
    };

    const getTitle = (weightKind: WeightKind): string => {
        if (weightKind === WeightKind.RevShare) return 'Fact Weights';
        if (weightKind === WeightKind.Bidding) return 'Fact Weights Bidding';
        return 'Fact Weights Bidding Internal';
    };

    const [date, setDate] = useState(getDefaultDate());
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        fetchProvidersList();
    }, []);

    useEffect(() => {
        fetchFactWeights(kind, date);
    }, [kind]);

    useEffect(() => {
        if (errorProvider) errorNotify('Provider error', errorProvider);
    }, [errorProvider]);

    useEffect(() => {
        if (errorCountry) errorNotify('Country error', errorCountry);
    }, [errorCountry]);

    const onDateChange: DatePickerProps['onChange'] = (d, dateString) => {
        setDate(dateString);
    };

    const search = () => {
        setShowErrorMessage(false);
        if (!date) {
            setShowErrorMessage(true);
            setErrorMessage('Date is required');
            return;
        }
        fetchFactWeights(kind, date);
    };

    return (
        <>
            <Title level={2} style={{ marginTop: 0 }}>{getTitle(kind)}</Title>
            <Row
                gutter={10}
                justify="start"
                align="middle"
                wrap={false}
                style={{ width: '100%', margin: 'auto', marginBottom: 16 }}
            >
                <Col>
                    <Text>Date: </Text><DatePicker onChange={onDateChange} defaultValue={dayjs(getDefaultDate(), dateFormat)} />
                </Col>
            </Row>
            <Row
                gutter={10}
                justify="start"
                align="middle"
                wrap={false}
                style={{ width: '100%', margin: 'auto', marginBottom: 16 }}
            >
                <Col>
                    <Button onClick={search} type="primary">Search</Button>
                </Col>
                <Col>
                    {showErrorMessage && <Text style={{ color: 'red' }}>{errorMessage}</Text>}
                </Col>
            </Row>
            <FactWeightMapTable
                loading={loadingProvider}
                error={errorProvider}
                factWeights={factWeights}
                providersList={providersList}
            />
        </>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(FactWeightMap);
