const { REACT_APP_API_BASE_URL, REACT_APP_REPORTS_BASE_URL } = process.env;
const headers = { 'Content-Type': 'application/json' };

const defaultApiOptions = {
    baseURL: REACT_APP_API_BASE_URL || 'http://localhost:8010',
    headers,
};

const reportApiOptions = {
    baseURL: REACT_APP_REPORTS_BASE_URL || 'http://localhost:8009',
    headers,
};

export {
    defaultApiOptions,
    reportApiOptions,
};
