import { useEffect, useState } from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Button, Typography, Row, Col } from 'antd';
import * as ProviderActionCreators from '../../store/actions/providerAction';
import * as CountriesActionCreators from '../../store/actions/countryAction';
import { RootState } from '../../store/reducers';
import { errorNotify, defaultNotify } from '../common/Notify';
import WeightMapTable from './WeightMapTable';
import { WeightKind } from '../../models/country';
import {
    isAdmin,
    isBiddingInternalWriter,
    isBiddingWriter,
    isDspRevShareWriter,
    isRevShareWriter,
} from '../../models/user';
import AddCountry from '../modals/AddCountry';

const { Title } = Typography;

const mapStateToProps = (state: RootState) => ({
    userState: state.user,
    providerState: state.provider,
    countryState: state.country,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
    const boundActions = bindActionCreators({
        ...ProviderActionCreators,
        ...CountriesActionCreators,
    }, dispatch);
    return {
        ...boundActions,
    };
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type ProviderProps = StateProps & DispatchProps & {
    kind: WeightKind;
};

const WeightMap: React.FC<ProviderProps> = props => {
    const { userState, providerState, countryState, kind } = props;
    const { groups } = userState;
    const { providersList, error: errorProvider, loading: loadingProvider } = providerState;
    const { countries, weights, loading: countryLoading, error: errorCountry } = countryState;
    const { fetchWeights, fetchCountries, fetchProvidersList, updateWeight, addNewCountry } = props;
    const [showAddModal, setShowAddModal] = useState(false);

    useEffect(() => {
        fetchCountries();
        fetchProvidersList();
    }, []);

    useEffect(() => {
        fetchWeights(kind);
    }, [kind]);

    useEffect(() => {
        if (errorProvider) errorNotify('Provider error', errorProvider);
    }, [errorProvider]);

    useEffect(() => {
        if (!countryLoading && errorCountry) {
            errorNotify('Country error', countryState.error);
            return;
        }
        if (showAddModal && !countryLoading && !errorCountry) {
            setShowAddModal(false);
            defaultNotify('New country successfully added', '');
        }
    }, [countryLoading]);

    const getTitle = (weightKind: WeightKind): string => {
        if (weightKind === WeightKind.RevShare) return 'Providers Weight Map by Country';
        if (weightKind === WeightKind.DSPRevShare) return 'Providers DSP Weight Map by Country';
        if (weightKind === WeightKind.Bidding) return 'Providers Bidding Weight Map by Country';
        return 'Providers Bidding Internal Weight Map by Country';
    };

    const handleAdd = () => {
        setShowAddModal(true);
    };

    const isAllowedUser = (): boolean => {
        if (isAdmin(groups)) return true;
        if (kind === WeightKind.RevShare) return isRevShareWriter(groups);
        if (kind === WeightKind.Bidding) return isBiddingWriter(groups);
        if (kind === WeightKind.BiddingInternal) return isBiddingInternalWriter(groups);
        if (kind === WeightKind.DSPRevShare) return isDspRevShareWriter(groups);
        return false;
    };

    return (
        <>
            <Title level={2} style={{ marginTop: 0 }}>{getTitle(kind)}</Title>
            {countries.length > 0
            && (
                <Row gutter={10} justify="start" align="middle" style={{ margin: 'auto', marginBottom: 16 }}>
                    {isAllowedUser()
                        && (
                            <Col>
                                <Button onClick={handleAdd} type="primary">Add country</Button>
                            </Col>
                        )}
                    <AddCountry
                        open={showAddModal}
                        setOpen={setShowAddModal}
                        countries={countries}
                        providersList={providersList}
                        addNewCountry={addNewCountry}
                        kind={kind}
                    />
                    <WeightMapTable
                        loading={loadingProvider}
                        error={errorProvider}
                        weights={weights}
                        countriesList={countries}
                        providersList={providersList}
                        groups={groups}
                        updateWeight={updateWeight}
                        kind={kind}
                    />
                </Row>
            )}
        </>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(WeightMap);
