import { useState } from 'react';
import { Form, FormInstance, InputNumber, Alert, Input } from 'antd';
import { Country } from '../../models/country';
import { BundleWeight } from '../../models/bundle';
import CountrySelect from '../common/CountrySelect';
import { ProviderListItem, ProviderName } from '../../models/provider';

interface BundleWeightFormProps {
    form: FormInstance<BundleWeight>;
    countries: Country[];
    providersList: ProviderListItem[];
    onFinish: ((values: any) => void);
}

const BundleWeightForm: React.FC<BundleWeightFormProps> = props => {
    const { countries, providersList, onFinish } = props;
    const weightRegex = /^[0-9][0-9]?$|^100$/;

    const defaultValue = providersList.map(provider => ({
        provider: provider.provider_name,
        weight: 0,
    }));

    const [weights, setWeights] = useState<{provider: ProviderName, weight: number}[]>(defaultValue);
    const [currCountry, setCurrCountry] = useState<string>('');
    const [validateError, setValidateError] = useState<string>();

    const weightRules = [
        { pattern: weightRegex, message: 'Please input a positive integer number! (in percent)' },
    ];

    const bundleRules = [
        { required: true, message: 'Please input bundle name!' },
    ];

    const countryRules = [
        { required: true, message: 'Please select country!' },
    ];

    const percentParser = (value: string | undefined): number => value ? +value.replace('%', '') : 0;

    const onWeightChange = (provider: string, val: number | null) => {
        if (!val) return;
        const newWeight = weights.map(w => {
            if (w.provider === provider) (w = { provider, weight: val });
            return w;
        });
        setWeights(newWeight);
    };

    const handleOk = (values: { [key: string]: any }) => {
        const { bundle, country } = values;
        const sum: number = weights.reduce((prev, curr) => prev + curr.weight, 0);
        if (sum !== 100) {
            setValidateError('Sum of providers weight must be 100');
            return;
        }
        onFinish({ bundle, country, weights });
    };

    const onFinishFailed = (errorInfo: any) => console.log('Failed:', errorInfo);

    return (
        <Form
            id="bundleWeightForm"
            layout="horizontal"
            labelCol={{ span: 4, offset: 4 }}
            labelWrap
            wrapperCol={{ span: 10 }}
            onFinish={handleOk}
            onFinishFailed={onFinishFailed}
        >
            <Form.Item
                label="Bundle"
                labelAlign="left"
                name="bundle"
                rules={bundleRules}
            >
                <Input style={{ width: '100%' }} />
            </Form.Item>
            <Form.Item
                label="Country"
                labelAlign="left"
                name="country"
                rules={countryRules}
            >
                <CountrySelect countries={countries} onSelect={setCurrCountry} />
            </Form.Item>
            {validateError && <Alert style={{ marginBottom: '20px' }} message={validateError} type="error" />}
            {providersList.map(provider => (provider.allowed_countries.includes(currCountry, 0) && (
                <Form.Item
                    key={provider.provider_name}
                    label={provider.provider_name}
                    labelAlign="left"
                    style={{ marginBottom: 10 }}
                    rules={weightRules}
                >
                    <InputNumber
                        min={0}
                        max={100}
                        name={provider.provider_name}
                        formatter={value => value ? `${value}%` : ''}
                        parser={percentParser}
                        onChange={val => onWeightChange(provider.provider_name, val)}
                    />
                </Form.Item>
            )))}
        </Form>
    );
};
export default BundleWeightForm;
