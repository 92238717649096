import { useState } from 'react';
import { Modal, Form, Button, notification } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';
import BundleForm from '../forms/BundleForm';
import Bundle from '../../models/bundle';
import formType from '../../types/form';
import { CountryPrice, DefaultPrice } from '../../models/price';
import { Country } from '../../models/country';
import { CreateAction } from '../../store/types/actions';

const { useForm } = Form;

interface AddBundleProps {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    bundlesNamesList: string[];
    defaultPrice: DefaultPrice;
    countries: Country[];
    error: null | string;
    addBundle: CreateAction<Bundle, void>;
}

const AddBundle: React.FC<AddBundleProps> = props => {
    const { open, setOpen, bundlesNamesList, defaultPrice, countries, error, addBundle } = props;
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [form] = useForm<Bundle>();

    const getPricesByCountryFromForm = (input: any): CountryPrice[] => {
        if (!input) {
            return [];
        }

        const typed: CountryPrice[] = input.map((countryPrice: any) => ({
            country: countryPrice.country,
            price: +countryPrice.price,
        }
        ));
        const countryPricesMap: { [key: string]: number } = typed.reduce((prev, current) => {
            const { country, price } = current;
            return { ...prev, [country]: price };
        }, {});
        const result: CountryPrice[] = Object
            .entries(countryPricesMap).map(([country, price]) => ({ country, price }));

        return result;
    };

    const handleOk = async (values: { [key: string]: any }) => {
        const { name, url, gp_url, price, price_by_country } = values;
        const pricesByCountry = getPricesByCountryFromForm(price_by_country);

        const newBundle: Bundle = {
            name,
            url,
            gp_url,
            price: +price,
            price_by_country: pricesByCountry,
            // TODO pass email here
            updated_by: '',
        };
        setConfirmLoading(true);
        await addBundle(newBundle);
        setConfirmLoading(false);
        // TODO error is empty on first unsuccessful request, but on second and onward it's ok.. what the fuck
        console.log('error: ', error);
        if (!error) {
            setOpen(false);
            notification.open({
                message: `${name} bundle added`,
                icon: <CheckCircleOutlined style={{ color: '#108ee9' }} />,
            });
        }
    };

    const handleCancel = () => {
        form.setFieldsValue({ price_by_country: [] });
        setOpen(false);
    };

    return (
        <Modal
            title="Add Bundle"
            open={open}
            confirmLoading={confirmLoading}
            onCancel={handleCancel}
            getContainer={false}
            footer={[
                <Button key="cancel" onClick={handleCancel}>
                    Cancel
                </Button>,
                <Button form="bundleForm" key="submit" htmlType="submit" type="primary" loading={confirmLoading}>
                    Submit
                </Button>,
            ]}
        >
            <BundleForm
                form={form}
                defaultPrice={defaultPrice}
                bundlesNamesList={bundlesNamesList}
                countries={countries}
                onFinish={handleOk}
                type={formType.ADD_FORM}
            />
        </Modal>
    );
};

export default AddBundle;
