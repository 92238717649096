import { AdblockAction, AdblockActionTypes, AdblockState } from '../types/adblock';

const defaultState: AdblockState = {
    adblockStats: [],
    adblockReportStats: [],
    adblockAggregatedStats: [],
    mostBlockedStats: [],
    loading: false,
    error: null,
};

const adblockReducer = (state = defaultState, action: AdblockAction): AdblockState => {
    switch (action.type) {
    case AdblockActionTypes.FETCH_ADBLOCK_STATS_REQUEST:
        return { ...state, loading: true, error: null };
    case AdblockActionTypes.FETCH_ADBLOCK_STATS_SUCCESS:
        return { ...state, adblockStats: action.payload, loading: false, error: null };
    case AdblockActionTypes.FETCH_ADBLOCK_STATS_ERROR:
        return { ...state, error: action.payload, loading: false };
    case AdblockActionTypes.FETCH_ADBLOCK_AGGREGATED_REQUEST:
        return { ...state, loading: true, error: null };
    case AdblockActionTypes.FETCH_ADBLOCK_AGGREGATED_SUCCESS:
        return { ...state, adblockAggregatedStats: action.payload, loading: false, error: null };
    case AdblockActionTypes.FETCH_ADBLOCK_AGGREGATED_ERROR:
        return { ...state, error: action.payload, loading: false };
    case AdblockActionTypes.FETCH_MOST_BLOCKED_REQUEST:
        return { ...state, loading: true, error: null };
    case AdblockActionTypes.FETCH_MOST_BLOCKED_SUCCESS:
        return { ...state, mostBlockedStats: action.payload, loading: false, error: null };
    case AdblockActionTypes.FETCH_MOST_BLOCKED_ERROR:
        return { ...state, error: action.payload, loading: false };
    case AdblockActionTypes.UNBLOCK_CRID_REQUEST:
        return { ...state, loading: true, error: null };
    case AdblockActionTypes.UNBLOCK_CRID_SUCCESS: {
        const adblockReportStats = state.adblockReportStats.filter(item => item.cr_id !== action.payload);
        return { ...state, adblockReportStats, loading: false, error: null };
    }
    case AdblockActionTypes.UNBLOCK_CRID_ERROR:
        return { ...state, error: action.payload, loading: false };
    case AdblockActionTypes.FETCH_ADBLOCK_REPORT_REQUEST:
        return { ...state, loading: true, error: null };
    case AdblockActionTypes.FETCH_ADBLOCK_REPORT_SUCCESS:
        return { ...state, adblockReportStats: action.payload, loading: false, error: null };
    case AdblockActionTypes.FETCH_ADBLOCK_REPORT_ERROR:
        return { ...state, error: action.payload, loading: false };
    default:
        return state;
    }
};

export default adblockReducer;
