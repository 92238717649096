import { AdblockAggregated, AdblockReportStat, AdblockStat, MostBlocked } from '../../models/adblock';

export interface AdblockState {
    adblockStats: AdblockStat[];
    adblockReportStats: AdblockReportStat[];
    adblockAggregatedStats: AdblockAggregated[];
    mostBlockedStats: MostBlocked[];
    loading: boolean;
    error: null | string;
}

export enum AdblockActionTypes {
    FETCH_ADBLOCK_STATS_REQUEST = 'FETCH_ADBLOCK_STATS_REQUEST',
    FETCH_ADBLOCK_STATS_SUCCESS = 'FETCH_ADBLOCK_STATS_SUCCESS',
    FETCH_ADBLOCK_STATS_ERROR = 'FETCH_ADBLOCK_STATS_ERROR',
    FETCH_ADBLOCK_AGGREGATED_REQUEST = 'FETCH_ADBLOCK_AGGREGATED_REQUEST',
    FETCH_ADBLOCK_AGGREGATED_SUCCESS = 'FETCH_ADBLOCK_AGGREGATED_SUCCESS',
    FETCH_ADBLOCK_AGGREGATED_ERROR = 'FETCH_ADBLOCK_AGGREGATED_ERROR',
    FETCH_MOST_BLOCKED_REQUEST = 'FETCH_MOST_BLOCKED_REQUEST',
    FETCH_MOST_BLOCKED_SUCCESS = 'FETCH_MOST_BLOCKED_SUCCESS',
    FETCH_MOST_BLOCKED_ERROR = 'FETCH_MOST_BLOCKED_ERROR',
    UNBLOCK_CRID_REQUEST = 'UNBLOCK_CRID_REQUEST',
    UNBLOCK_CRID_SUCCESS = 'UNBLOCK_CRID_SUCCESS',
    UNBLOCK_CRID_ERROR = 'UNBLOCK_CRID_ERROR',
    FETCH_ADBLOCK_REPORT_REQUEST = 'FETCH_ADBLOCK_REPORT_REQUEST',
    FETCH_ADBLOCK_REPORT_SUCCESS = 'FETCH_ADBLOCK_REPORT_SUCCESS',
    FETCH_ADBLOCK_REPORT_ERROR = 'FETCH_ADBLOCK_REPORT_ERROR',
}

interface FetchAdblockStatsRequestAction {
    type: AdblockActionTypes.FETCH_ADBLOCK_STATS_REQUEST;
}

interface FetchAdblockStatsSuccessAction {
    type: AdblockActionTypes.FETCH_ADBLOCK_STATS_SUCCESS;
    payload: AdblockStat[];
}

interface FetchAdblockStatsErrorAction {
    type: AdblockActionTypes.FETCH_ADBLOCK_STATS_ERROR;
    payload: string;
}

interface FetchAdblockAggregatedRequestAction {
    type: AdblockActionTypes.FETCH_ADBLOCK_AGGREGATED_REQUEST;
}

interface FetchAdblockAggregatedSuccessAction {
    type: AdblockActionTypes.FETCH_ADBLOCK_AGGREGATED_SUCCESS;
    payload: AdblockAggregated[];
}

interface FetchAdblockAggregatedErrorAction {
    type: AdblockActionTypes.FETCH_ADBLOCK_AGGREGATED_ERROR;
    payload: string;
}

interface FetchMostBlockedRequestAction {
    type: AdblockActionTypes.FETCH_MOST_BLOCKED_REQUEST;
}

interface FetchMostBlockedSuccessAction {
    type: AdblockActionTypes.FETCH_MOST_BLOCKED_SUCCESS;
    payload: MostBlocked[];
}

interface FetchMostBlockedErrorAction {
    type: AdblockActionTypes.FETCH_MOST_BLOCKED_ERROR;
    payload: string;
}

interface UnblockCrIDRequestAction {
    type: AdblockActionTypes.UNBLOCK_CRID_REQUEST;
}

interface UnblockCrIDSuccessAction {
    type: AdblockActionTypes.UNBLOCK_CRID_SUCCESS;
    payload: string;
}

interface UnblockCrIDErrorAction {
    type: AdblockActionTypes.UNBLOCK_CRID_ERROR;
    payload: string;
}

interface FetchAdblockReportRequestAction {
    type: AdblockActionTypes.FETCH_ADBLOCK_REPORT_REQUEST;
}

interface FetchAdblockReportSuccessAction {
    type: AdblockActionTypes.FETCH_ADBLOCK_REPORT_SUCCESS;
    payload: AdblockReportStat[];
}

interface FetchAdblockReportErrorAction {
    type: AdblockActionTypes.FETCH_ADBLOCK_REPORT_ERROR;
    payload: string;
}

export type AdblockAction = FetchAdblockStatsRequestAction |
    FetchAdblockStatsSuccessAction |
    FetchAdblockStatsErrorAction |
    FetchAdblockAggregatedRequestAction |
    FetchAdblockAggregatedSuccessAction |
    FetchAdblockAggregatedErrorAction |
    FetchMostBlockedRequestAction |
    FetchMostBlockedSuccessAction |
    FetchMostBlockedErrorAction |
    UnblockCrIDRequestAction |
    UnblockCrIDSuccessAction |
    UnblockCrIDErrorAction |
    FetchAdblockReportRequestAction |
    FetchAdblockReportSuccessAction |
    FetchAdblockReportErrorAction;
