import { useContext, useState } from 'react';
import { Button, Form, Modal } from 'antd';
import CountryProvidersForm from '../forms/CountryProvidersForm';
import Bundle from '../../models/bundle';
import { Country, Weight, WeightKind } from '../../models/country';
import { CreateAction } from '../../store/types/actions';
import { ProviderListItem, ProviderName } from '../../models/provider';
import { Context } from '../../Context';

const { useForm } = Form;

interface AddCountryProps {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    countries: Country[];
    providersList: ProviderListItem[];
    addNewCountry: CreateAction<Weight, void>;
    kind: WeightKind;
}

const AddCountry: React.FC<AddCountryProps> = props => {
    const { open, setOpen, countries, providersList, addNewCountry, kind } = props;
    const { email } = useContext(Context);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [form] = useForm<Bundle>();

    const handleOk = async (values: { [key: string]: any }) => {
        const { country, weights, bid_floor_price_scale, provider_price_scale } = values;
        const weightsMap = weights
            .filter((w: { provider: ProviderName; weight: number; }) => w.weight > 0)
            .reduce((
                    acc: { provider: ProviderName; weight: number; },
                    cur: { provider: ProviderName; weight: number; },
                ) => ({ ...acc, [cur.provider]: cur.weight }), {});
        const newCountry: Weight = {
            active: true,
            country,
            weights: weightsMap,
            weight_kind: kind,
            bid_floor_price_scale,
            provider_price_scale,
            updated_by: email,
            updated_at: new Date().toISOString(),
        };
        setConfirmLoading(true);
        await addNewCountry(newCountry);
        setConfirmLoading(false);
    };

    const handleCancel = () => {
        setOpen(false);
    };

    return (
        <Modal
            title="Add Country"
            open={open}
            confirmLoading={confirmLoading}
            onCancel={handleCancel}
            getContainer={false}
            footer={[
                <Button key="cancel" onClick={handleCancel}>
                    Cancel
                </Button>,
                <Button form="bundleForm" key="submit" htmlType="submit" type="primary" loading={confirmLoading}>
                    Submit
                </Button>,
            ]}
        >
            <CountryProvidersForm
                form={form}
                countries={countries}
                providersList={providersList}
                kind={kind}
                onFinish={handleOk}
            />
        </Modal>
    );
};

export default AddCountry;
