import { Form } from 'antd';
import { Country } from '../../models/country';
import CountrySelect from '../common/CountrySelect';

interface ProviderBarProps {
    countries: Country[];
    disabledCountries?: Country[];
    selectCountry: (value: string) => void;
}

const ProviderBar: React.FC<ProviderBarProps> = props => {
    const { countries, disabledCountries, selectCountry } = props;

    return (
        <Form
            layout="inline"
            style={{ marginBottom: 16 }}
        >
            <Form.Item style={{ width: 250 }}>
                <CountrySelect
                    countries={countries}
                    disabledCountries={disabledCountries}
                    onSelect={selectCountry}
                />
            </Form.Item>
        </Form>
    );
};

export default ProviderBar;
