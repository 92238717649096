import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { compareDates, formatDateTime } from '../../helpers/date';
import { PostData } from '../../models/provider';

interface ProviderTableProps {
    loading: boolean;
    posts?: PostData[];
}

const ProviderTable: React.FC<ProviderTableProps> = props => {
    const { loading, posts } = props;

    const columns: ColumnsType<PostData> = [
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
            width: '30%',
        },
        {
            title: 'Last updated',
            dataIndex: 'updated_at',
            key: 'updatedAt',
            align: 'right',
            width: '35%',
            render: updatedAt => <span>{formatDateTime(updatedAt)}</span>,
            sorter: (a, b) => compareDates(a.updated_at || '', b.updated_at || ''),
        },
        {
            title: 'Created',
            dataIndex: 'created_at',
            key: 'createdAt',
            align: 'right',
            width: '35%',
            render: createdAt => <span>{formatDateTime(createdAt)}</span>,
            sorter: (a, b) => compareDates(a.created_at || '', b.created_at || ''),
        },
    ];

    return (
        <Table
            loading={loading}
            rowKey={record => record.post_id}
            dataSource={posts}
            columns={columns}
            pagination={false}
        />
    );
};

export default ProviderTable;
