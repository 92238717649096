import { useEffect, useState } from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Typography } from 'antd';
import type { CheckboxValueType } from 'antd/es/checkbox/Group';
import * as CountriesActionCreators from '../../store/actions/countryAction';
import * as LURLReportActionCreators from '../../store/actions/lurlReportAction';
import * as ProviderActionCreators from '../../store/actions/providerAction';
import { RootState } from '../../store/reducers';
import { errorNotify } from '../common/Notify';
import LURLReportTable from './LURLReportTable';
import LURLReportBar from './LURLReportBar';

const { Title } = Typography;

const mapStateToProps = (state: RootState) => ({
    userState: state.user,
    countryState: state.country,
    lurlReportState: state.lurlReport,
    providerState: state.provider,
});

const mapDispatchToProps = (dispatch: Dispatch) => {
    const boundActions = bindActionCreators({
        ...CountriesActionCreators,
        ...LURLReportActionCreators,
        ...ProviderActionCreators,
    }, dispatch);
    return {
        ...boundActions,
    };
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type LURLReportProps = StateProps & DispatchProps;

const LURLReport: React.FC<LURLReportProps> = props => {
    const { userState, countryState, lurlReportState, providerState } = props;
    const { groups } = userState;
    const { countries, error: errorCountry } = countryState;
    const { lurlStats, lurlReasons, error: errorLURLReport, loading } = lurlReportState;
    const { providersList, error: errorProvider } = providerState;
    const { fetchCountries, fetchLURLReport, fetchProvidersList, fetchLURLReasons } = props;

    const groupByOptions: Record<string, string> = {
        country: 'Country',
        provider: 'Provider',
        tdm: 'Traffic distribute mode',
        zone: 'Zone',
    };
    const [groupByList, setGroupByList] = useState<string[]>([]);
    const onGroupByChange = (checkedValues: CheckboxValueType[]) => {
        setGroupByList(checkedValues.map(item => item.toString()));
    };

    useEffect(() => {
        fetchCountries();
        fetchProvidersList();
        fetchLURLReasons();
    }, []);

    useEffect(() => {
        if (errorCountry) errorNotify('Country error', errorCountry);
    }, [errorCountry]);

    useEffect(() => {
        if (errorLURLReport) errorNotify('LURL report error', errorLURLReport);
    }, [errorLURLReport]);

    useEffect(() => {
        if (errorProvider) errorNotify('Provider error', errorProvider);
    }, [errorProvider]);

    return (
        <>
            <Title level={2} style={{ marginTop: 0 }}>LURL Report</Title>
            <LURLReportBar
                fetchLurlReport={fetchLURLReport}
                lurlStats={lurlStats}
                lurlReasons={lurlReasons}
                countries={countries}
                providersList={providersList}
                groupByList={groupByList}
                groupByOptions={groupByOptions}
                onGroupByChange={onGroupByChange}
            />
            <LURLReportTable
                loading={loading}
                error={errorLURLReport}
                lurlStats={lurlStats}
                countries={countries}
                groups={groups}
                groupByList={groupByList}
            />
        </>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(LURLReport);
