import { Button, Form, FormInstance, Input, Space } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Country } from '../../models/country';
import { DefaultPrice } from '../../models/price';
import Bundle from '../../models/bundle';
import formType from '../../types/form';
import CountrySelect from '../common/CountrySelect';

const appGalleryURL = 'https://appgallery.cloud.huawei.com/ag/n/app/';

type BundleWithHagID = Bundle & {
    hagID?: string;
};

interface BundleFormProps {
    form: FormInstance<Bundle>;
    bundle?: Bundle;
    bundlesNamesList?: string[];
    defaultPrice: DefaultPrice;
    countries: Country[];
    onFinish: ((values: any) => void);
    type: formType;
}

const BundleForm: React.FC<BundleFormProps> = props => {
    const { bundle, bundlesNamesList, defaultPrice, countries, onFinish, type } = props;
    const handleOk = (values: { [key: string]: any }) => {
        let { name, gp_url } = values;
        name = name.trim();
        if (gp_url) gp_url = gp_url.trim();
        const { hagID, price, price_by_country } = values;
        const url = hagID ? appGalleryURL + hagID.trim() : '';
        onFinish({ name, url, gp_url, price, price_by_country });
    };

    const urlRegex = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/;
    const priceRegex = /^[1-9]\d*$/;
    const hagIDRegex = /C\d+$/;

    const getInitialValues = (): BundleWithHagID => {
        if (bundle) {
            const existingBundle: BundleWithHagID = bundle;
            existingBundle.hagID = bundle.url.slice(appGalleryURL.length);
            existingBundle.gp_url = bundle.gp_url;
            return existingBundle;
        }
        return {
            name: '',
            url: '',
            gp_url: '',
            price: defaultPrice.price,
            price_by_country: [],
            updated_by: '',
        };
    };

    const validateName = (rule: any, value: string) => (
        value && bundlesNamesList?.includes(value)
            ? Promise.reject(new Error('Bundle with this name already exists!'))
            : Promise.resolve()
    );

    const nameRules = [
        { required: true, message: 'Please input bundle name!' },
        { validator: validateName },
    ];

    const hagIDRules = [
        { pattern: hagIDRegex, message: 'Please input valid App Gallery ID!' },
    ];

    const priceRules = [
        { required: true, message: 'Please input price!' },
        { pattern: priceRegex, message: 'Please input a positive integer number!' },
    ];

    const countryRules = [
        { required: true, message: 'Please select country!' },
    ];

    const onFinishFailed = (errorInfo: any) => console.log('Failed:', errorInfo);

    const shouldUpdate = (prevValues: any, curValues: any) => (
        prevValues.area !== curValues.area || prevValues.sights !== curValues.sights
    );

    return (
        <Form
            id="bundleForm"
            layout="horizontal"
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 20 }}
            initialValues={getInitialValues()}
            onFinish={handleOk}
            onFinishFailed={onFinishFailed}
        >
            <Form.Item
                label="Name"
                labelAlign="left"
                name="name"
                rules={nameRules}
            >
                <Input style={{ width: '100%' }} />
            </Form.Item>
            <Form.Item
                label="HAG ID"
                labelAlign="left"
                name="hagID"
                tooltip="Enter Huawei App Gallery ID, e.g. C123456789"
                rules={hagIDRules}
            >
                <Input />
            </Form.Item>
            {type === formType.EDIT_FORM ? (
                <Form.Item
                    label="GP URL"
                    labelAlign="left"
                    name="gp_url"
                    tooltip="Google Play URL"
                >
                    <Input readOnly />
                </Form.Item>
            ) : ''}
            <Form.Item
                label="Price"
                labelAlign="left"
                wrapperCol={{ span: 5 }}
                name="price"
                rules={priceRules}
            >
                <Input type="number" min="1" />
            </Form.Item>
            <Form.List name="price_by_country">
                {(fields, { add, remove }) => (
                    <>
                        {fields.map(field => (
                            <Space key={field.key} align="baseline">
                                <Form.Item
                                    noStyle
                                    shouldUpdate={shouldUpdate}
                                >
                                    {() => (
                                        <Form.Item
                                            {...field}
                                            style={{ width: 250 }}
                                            label="Country"
                                            labelAlign="left"
                                            labelCol={{ offset: 0 }}
                                            name={[field.name, 'country']}
                                            fieldKey={[field.key, 'country']}
                                            rules={countryRules}
                                        >
                                            <CountrySelect countries={countries} />
                                        </Form.Item>
                                    )}
                                </Form.Item>
                                <Form.Item
                                    {...field}
                                    label="Price"
                                    labelAlign="left"
                                    labelCol={{ offset: 0 }}
                                    name={[field.name, 'price']}
                                    fieldKey={[field.key, 'price']}
                                    rules={priceRules}
                                    initialValue={defaultPrice.price}
                                >
                                    <Input type="number" min="1" value={field.name} />
                                </Form.Item>
                                <MinusCircleOutlined onClick={() => remove(field.name)} />
                            </Space>
                        ))}

                        <Form.Item style={{ justifyContent: 'center' }}>
                            <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                Add country price
                            </Button>
                        </Form.Item>
                    </>
                )}
            </Form.List>
        </Form>
    );
};

export default BundleForm;
