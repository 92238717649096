import { useState } from 'react';
import { Space, Button, Popconfirm, notification } from 'antd';
import { DeleteOutlined, CheckCircleOutlined, EditOutlined } from '@ant-design/icons';
import { BundleWeight } from '../../models/bundle';
import EditBundleWeight from '../modals/EditBundleWeight';
import { Country } from '../../models/country';
import { UpdateAction, DeleteAction } from '../../store/types/actions';
import { ProviderListItem } from '../../models/provider';
import { defaultNotify } from '../common/Notify';

interface WeightMapPerBundleActionsProps {
    weight: BundleWeight;
    countries: Country[];
    error: null | string;
    providersList: ProviderListItem[];
    updateBundleWeight: UpdateAction<BundleWeight, void>;
    deleteBundleWeight: DeleteAction;
}

const WeightMapPerBundleActions: React.FC<WeightMapPerBundleActionsProps> = props => {
    const { weight, countries, error, providersList, updateBundleWeight, deleteBundleWeight } = props;
    const [showUpdateModal, setShowUpdateModal] = useState(false);

    const handleDelete = () => {
        const { id, bundle } = weight;
        deleteBundleWeight(id);
        defaultNotify(`${bundle} bundle weight deleted`, '');
    };

    return (
        <Space size="small">
            <EditBundleWeight
                weight={weight}
                countries={countries}
                open={showUpdateModal}
                setOpen={setShowUpdateModal}
                providersList={providersList}
                updateBundleWeight={updateBundleWeight}
                error={error}
            />
            <Button shape="circle" icon={<EditOutlined />} onClick={() => setShowUpdateModal(true)} />
            <Popconfirm
                placement="topRight"
                title="Are you sure to delete this bundle weight?"
                onConfirm={() => handleDelete()}
                okText="Yes"
                cancelText="No"
            >
                <Button shape="circle" icon={<DeleteOutlined />} />
            </Popconfirm>
        </Space>
    );
};

export default WeightMapPerBundleActions;
