import { configureStore } from '@reduxjs/toolkit';
import axios from 'axios';
import { rootReducer } from './reducers';
import { defaultApiOptions, reportApiOptions } from '../api';

const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        thunk: {
            extraArgument: {
                defaultAPI: axios.create(defaultApiOptions),
                reportsAPI: axios.create(reportApiOptions),
            },
        },
        immutableCheck: false,
        serializableCheck: {
            // Redux cannot serialize CognitoSession or CognitoIdToken objects
            ignoreActions: true,
        },
    }),
});

export default store;
