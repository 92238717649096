import { Divider, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { withKeys } from '../../helpers/array';
import { Country, getCountryFullName } from '../../models/country';
import { CountryPrice } from '../../models/price';

type CountryPriceColumn = { key: string } & CountryPrice;

interface CountryPricesProps {
    data: CountryPrice[];
    countriesList: Country[];
}

const CountryPrices: React.FC<CountryPricesProps> = ({ data, countriesList }) => {
    const columns: ColumnsType<CountryPriceColumn> = [
        {
            title: 'Country',
            dataIndex: 'country',
            key: 'country',
            width: '70%',
            render: (country: string) => getCountryFullName(country),
        },
        {
            title: 'Price',
            dataIndex: 'price',
            key: 'price',
            width: '30%',
            align: 'right',
        },
    ];

    return (
        <>
            <Table rowKey={record => record.key} columns={columns} dataSource={withKeys(data)} pagination={false} />
            <Divider />
        </>
    );
};

export default CountryPrices;
