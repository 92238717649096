import React, { useRef } from 'react';
import { Select } from 'antd';
import { BaseSelectRef } from 'rc-select';
import { UserCategory } from '../../models/userCategory';

const { Option } = Select;

interface UserCategorySelectProps {
    onSelect?: (value: string) => void;
    value?: string;
    onChange?: (value: string) => void;
}

const UserCategorySelect: React.FC<UserCategorySelectProps> = props => {
    const { onSelect, value, onChange } = props;

    const selectEl = useRef<BaseSelectRef>(null);

    const onUserCategorySelect = (e: string) => {
        selectEl.current!.blur();
        if (onSelect) {
            onSelect(e);
        }
    };

    return (
        <Select
            value={value}
            defaultValue={value}
            ref={selectEl}
            style={{ width: 120 }}
            optionFilterProp="children"
            placeholder="Select user category"
            onSelect={onUserCategorySelect}
            onChange={onChange}
            allowClear
        >
            {[...Object.entries(UserCategory)].map(item => <Option key={item[0]} value={item[0]}>{item[1]}</Option>)}
        </Select>
    );
};

export default UserCategorySelect;
