import countriesMap from './countriesMap';
import { ProviderName } from './provider';

interface Country {
    full_name: string;
    short_name: string;
}

export const getCountryFullName = (code: string) => {
    const country = countriesMap[code];

    if (!country) return code;

    return country;
};

export const sortCountries = (a: Country, b: Country) => a.full_name.localeCompare(b.full_name);

interface Weight {
    active: boolean;
    country: string;
    weight_kind: WeightKind;
    weights?: Record<ProviderName, number>;
    bid_floor_price_scale: number;
    provider_price_scale: number;
    updated_at?: string;
    updated_by: string;
}

interface FactWeight {
    country: string;
    weight_kind: WeightKind;
    weights?: Record<ProviderName, number>;
    date: string;
}

export enum WeightKind {
    RevShare = 'rev_share',
    Bidding = 'bidding',
    DSPRevShare = 'dsp_rev_share',
    BiddingInternal = 'bidding_internal',
}

export const WeightKindMap: Record<string, string> = {
    0: WeightKind.Bidding,
    1: WeightKind.RevShare,
};

export type {
    Country,
    Weight,
    FactWeight,
};
