import React, { useRef } from 'react';
import { Select } from 'antd';
import { BaseSelectRef } from 'rc-select';
import { Country, sortCountries } from '../../models/country';

const { Option } = Select;

type CountryWithDisabled = { disabled: boolean } & Country;

interface CountrySelectProps {
    countries: Country[];
    disabledCountries?: Country[];
    onSelect?: (value: string) => void;
    value?: string;
    onChange?: (value: string) => void;
}

const CountrySelect: React.FC<CountrySelectProps> = props => {
    const { countries, disabledCountries, onSelect, value, onChange } = props;

    const selectEl = useRef<BaseSelectRef>(null);

    const onCountrySelect = (e: string) => {
        selectEl.current!.blur();
        if (onSelect) {
            onSelect(e);
        }
    };

    const markDisabledCountries: (country: Country) => CountryWithDisabled = country => {
        const disabled = disabledCountries ? disabledCountries.includes(country) : false;
        return {
            ...country,
            disabled,
        };
    };

    const renderOption = (country: CountryWithDisabled) => (
        <Option
            disabled={country.disabled}
            key={country.short_name}
            value={country.short_name}
        >
            {country.full_name}
        </Option>
    );

    return (
        <Select
            showSearch
            value={value}
            defaultValue={undefined}
            ref={selectEl}
            optionFilterProp="children"
            placeholder="Select country"
            onSelect={onCountrySelect}
            onChange={onChange}
            allowClear
        >
            {[...countries].sort(sortCountries)
                .map(markDisabledCountries)
                .map(renderOption)}
        </Select>
    );
};

export default CountrySelect;
