import { IncomingInfo } from '../../models/incoming';

export interface IncomingReportState {
    incomingInfoStats: IncomingInfo[];
    loading: boolean;
    error: null | string;
}

export enum IncomingReportActionTypes {
    FETCH_INCOMING_REPORT_REQUEST = 'FETCH_INCOMING_REPORT_REQUEST',
    FETCH_INCOMING_REPORT_SUCCESS = 'FETCH_INCOMING_REPORT_SUCCESS',
    FETCH_INCOMING_REPORT_ERROR = 'FETCH_INCOMING_REPORT_ERROR',
}

interface FetchIncomingReportRequestAction {
    type: IncomingReportActionTypes.FETCH_INCOMING_REPORT_REQUEST;
}

interface FetchIncomingReportSuccessAction {
    type: IncomingReportActionTypes.FETCH_INCOMING_REPORT_SUCCESS;
    payload: IncomingInfo[];
}

interface FetchIncomingReportErrorAction {
    type: IncomingReportActionTypes.FETCH_INCOMING_REPORT_ERROR;
    payload: string;
}

export type IncomingReportAction = FetchIncomingReportRequestAction |
    FetchIncomingReportSuccessAction |
    FetchIncomingReportErrorAction;
