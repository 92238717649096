import React, { useRef } from 'react';
import { Select } from 'antd';
import { BaseSelectRef } from 'rc-select';
import { WeightKind, WeightKindMap } from '../../models/country';

const { Option } = Select;

interface TDMSelectProps {
    onSelect?: (value: string) => void;
    value?: string;
    onChange?: (value: string) => void;
}

const TDMSelect: React.FC<TDMSelectProps> = props => {
    const { onSelect, value, onChange } = props;

    const selectEl = useRef<BaseSelectRef>(null);

    const onTDMSelect = (e: string) => {
        selectEl.current!.blur();
        if (onSelect) {
            onSelect(e);
        }
    };

    const renderOption = (item: string, index: number) => (
        <Option
            key={item}
            value={index}
        >
            {item}
        </Option>
    );

    return (
        <Select
            value={value}
            defaultValue={undefined}
            ref={selectEl}
            style={{ width: 120 }}
            optionFilterProp="children"
            placeholder="Select TDM"
            onSelect={onTDMSelect}
            onChange={onChange}
            allowClear
        >
            {[...Object.values(WeightKindMap)].map(renderOption)}
        </Select>
    );
};

export default TDMSelect;
