import React, { useRef } from 'react';
import { Select } from 'antd';
import { BaseSelectRef } from 'rc-select';
import { ProviderListItem, sortProviders } from '../../models/provider';

const { Option } = Select;

interface ProviderSelectProps {
    providers: ProviderListItem[];
    onSelect?: (value: string) => void;
    value?: string;
    onChange?: (value: string) => void;
}

const ProviderSelect: React.FC<ProviderSelectProps> = props => {
    const { providers, onSelect, value, onChange } = props;

    const selectEl = useRef<BaseSelectRef>(null);

    const onProviderSelect = (e: string) => {
        selectEl.current!.blur();
        if (onSelect) {
            onSelect(e);
        }
    };

    const renderOption = (provider: ProviderListItem) => (
        <Option
            key={provider.id}
            value={provider.provider_name}
        >
            {provider.provider_name}
        </Option>
    );

    return (
        <Select
            showSearch
            value={value}
            defaultValue={undefined}
            ref={selectEl}
            optionFilterProp="children"
            placeholder="Select provider"
            onSelect={onProviderSelect}
            onChange={onChange}
            allowClear
        >
            {[...providers].sort(sortProviders)
                .map(renderOption)}
        </Select>
    );
};

export default ProviderSelect;
