import { useContext } from 'react';
import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { getCountryFullName, WeightKindMap } from '../../models/country';
import { UserGroups } from '../../models/user';
import { withKeys } from '../../helpers/array';
import { compareDates, formatDate } from '../../helpers/date';
import { Performance } from '../../models/performance';
import { AWSRegionTypeMap } from '../../models/awsRegion';
import { compareValues } from '../../helpers/compare';
import { Truncate } from '../../models/truncate';
import { UserConsentMap } from '../../models/errorReport';
import { Context } from '../../Context';

interface performanceReportColumn {
    key: string;
    date: string;
    traffic_distribute_mode: string;
    country: string;
    application: string;
    provider: string;
    content_provider: string;
    user_category: string;
    kind_app: string;
    zone: string;
    user_consent: number;
    requests: number;
    http200_resp: number;
    wurls: number;
    lurls: number;
    impressions: number;
    clicks: number;
    fillin_rate: number;
    drop_rate: number;
    win_rate: number;
    viewability: number;
    ctr: number;
    region: string;
}

interface PerformanceReportTableProps {
    loading: boolean;
    error: null | string;
    performanceStats: Performance[];
    groupByList: string[];
    groups: UserGroups[];
}

const PerformanceReportTable: React.FC<PerformanceReportTableProps> = props => {
    const { loading, performanceStats, groupByList } = props;
    const { email } = useContext(Context);
    const defaultPageSize = 30;

    const getScrollX = () => 1300 + 100 * groupByList.length;

    const getColumns = (): ColumnsType<performanceReportColumn> => {
        const groupColumns: ColumnsType<performanceReportColumn> = [
            {
                title: 'Date',
                dataIndex: 'date',
                key: 'date',
                align: 'right',
                width: 150,
                sorter: (a, b) => compareDates(a.date || '', b.date || ''),
                defaultSortOrder: 'ascend',
                render: date => <span style={{ whiteSpace: 'nowrap', fontSize: 13 }}>{formatDate(date)}</span>,
            },
            {
                title: 'AWS Region',
                dataIndex: 'region',
                key: 'region',
                align: 'left',
                width: 50,
                sorter: (a, b) => a.region.localeCompare(b.region),
                render: (region: string): string => AWSRegionTypeMap[region],
            },
            {
                title: 'Country',
                dataIndex: 'country',
                key: 'country',
                align: 'left',
                width: 150,
                sorter: (a, b) => compareValues(a.country, b.country),
                render: (country: string) => getCountryFullName(country),
            },
            {
                title: 'Bundle',
                dataIndex: 'application',
                key: 'application',
                align: 'right',
                width: 100,
                sorter: (a, b) => compareValues(a.application, b.application),
            },
            {
                title: 'Provider',
                dataIndex: 'provider',
                key: 'provider',
                align: 'left',
                width: 150,
                sorter: (a, b) => compareValues(a.provider, b.provider),
            },
            {
                title: 'Content provider',
                dataIndex: 'content_provider',
                key: 'content_provider',
                align: 'left',
                width: 150,
                sorter: (a, b) => a.content_provider.localeCompare(b.content_provider),
            },
            {
                title: 'API',
                dataIndex: 'kind_app',
                key: 'kind_app',
                align: 'left',
                width: 70,
                sorter: (a, b) => compareValues(a.kind_app, b.kind_app),
            },
            {
                title: 'Zone',
                dataIndex: 'zone',
                key: 'zone',
                align: 'left',
                width: 70,
                sorter: (a, b) => a.zone.localeCompare(b.zone),
            },
            {
                title: 'Traffic distribute mode',
                dataIndex: 'traffic_distribute_mode',
                key: 'traffic_distribute_mode',
                align: 'left',
                width: 100,
                sorter: (a, b) => (
                    a.traffic_distribute_mode.toString().localeCompare(b.traffic_distribute_mode.toString())
                ),
                render: (tdm: string): string => WeightKindMap[tdm],
            },
            {
                title: 'User category',
                dataIndex: 'user_category',
                key: 'user_category',
                align: 'left',
                width: 100,
                sorter: (a, b) => a.user_category.toString().localeCompare(b.user_category.toString()),
            },
            {
                title: 'User consent',
                dataIndex: 'user_consent',
                key: 'user_consent',
                align: 'right',
                fixed: 'left',
                sorter: (a, b) => a.user_consent.toString().localeCompare(b.user_consent.toString()),
                render: (userConsent: string): string => UserConsentMap[userConsent],
            },
        ];

        const filtered = groupByList.map(item => {
            if (Object.values(Truncate).includes(item as Truncate)) item = 'date';
            return groupColumns.find(col => col.key!.toString() === item)!;
        });

        const columns: ColumnsType<performanceReportColumn> = [
            ...filtered,
            {
                title: 'Requests',
                dataIndex: 'requests',
                key: 'requests',
                align: 'right',
                width: 100,
                sorter: (a, b) => a.requests - b.requests,
                render: req => req.toLocaleString(),
            },
            {
                title: 'Responses',
                dataIndex: 'http200_resp',
                key: 'http200_resp',
                align: 'right',
                width: 100,
                sorter: (a, b) => a.http200_resp - b.http200_resp,
                render: resp => resp.toLocaleString(),
            },
            {
                title: 'WURLs',
                dataIndex: 'wurls',
                key: 'wurls',
                align: 'right',
                width: 100,
                sorter: (a, b) => a.wurls - b.wurls,
                render: wurls => wurls.toLocaleString(),
            },
            {
                title: 'LURLs',
                dataIndex: 'lurls',
                key: 'lurls',
                align: 'right',
                width: 100,
                sorter: (a, b) => a.lurls - b.lurls,
                render: lurls => lurls.toLocaleString(),
            },
            {
                title: 'Impressions',
                dataIndex: 'impressions',
                key: 'impressions',
                align: 'right',
                width: 100,
                sorter: (a, b) => a.impressions - b.impressions,
                render: imp => imp.toLocaleString(),
            },
            {
                title: 'Clicks',
                dataIndex: 'clicks',
                key: 'clicks',
                align: 'right',
                width: 100,
                sorter: (a, b) => a.clicks - b.clicks,
                render: clicks => clicks.toLocaleString(),
            },
            {
                title: 'Fillin rate',
                dataIndex: 'fillin_rate',
                key: 'fillin_rate',
                align: 'right',
                width: 100,
                sorter: (a, b) => a.fillin_rate - b.fillin_rate,
                render: fillin_rate => fillin_rate.toFixed(2),
            },
            {
                title: 'Drop Ratio',
                dataIndex: 'drop_rate',
                key: 'drop_rate',
                align: 'right',
                width: 100,
                sorter: (a, b) => a.drop_rate - b.drop_rate,
                render: drop_rate => drop_rate.toFixed(2),
            },
            {
                title: 'Win Ratio',
                dataIndex: 'win_rate',
                key: 'win_rate',
                align: 'right',
                width: 100,
                sorter: (a, b) => a.win_rate - b.win_rate,
                render: win_rate => win_rate.toFixed(2),
            },
            {
                title: 'Viewability',
                dataIndex: 'viewability',
                key: 'viewability',
                align: 'right',
                width: 100,
                sorter: (a, b) => a.viewability - b.viewability,
                render: viewability => viewability.toFixed(2),
            },
            {
                title: 'CTR',
                dataIndex: 'ctr',
                key: 'ctr',
                align: 'right',
                width: 100,
                sorter: (a, b) => a.ctr - b.ctr,
                render: ctr => ctr.toFixed(2),
            },
        ];

        return columns;
    };

    return (
        <Table
            className="performanceReport__table"
            style={{ width: '90%', margin: 'auto' }}
            bordered
            loading={loading}
            rowKey={record => record.key}
            dataSource={withKeys(performanceStats)}
            columns={getColumns()}
            pagination={{ defaultPageSize }}
            scroll={{ x: getScrollX(), y: '53vh' }}
            tableLayout="auto"
            size="small"
        />
    );
};

export default PerformanceReportTable;
