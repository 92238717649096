import { AxiosError } from 'axios';
import queryString from 'query-string';
import { ErrorReportStats, FetchErrorReportRequest } from '../../models/errorReport';
import { authInterceptor } from './interceptors';
import { ErrorReportAction, ErrorReportActionTypes } from '../types/errorReport';
import { handleError } from '../../helpers/error';
import { Action } from '../types/actions';

// eslint-disable-next-line
export const fetchErrorReport = (req: FetchErrorReportRequest): Action<ErrorReportAction> => {
    const { from, to, region, country_code, rq_bundle, v_provider, is_general_provider, groupBy,
        kind_app, traffic_distribute_mode, is_200, user_category, user_consent, zone } = req;
    const query = queryString.stringify(
        {
            from,
            to,
            region,
            country_code,
            rq_bundle,
            v_provider,
            is_general_provider,
            traffic_distribute_mode,
            kind_app,
            is_200,
            user_category,
            user_consent,
            zone,
            group_by: groupBy,
        },
        { arrayFormat: 'comma' },
    );

    return async (dispatch, getState, { reportsAPI }) => {
        dispatch({ type: ErrorReportActionTypes.FETCH_ERROR_REPORT_REQUEST });
        reportsAPI.interceptors.request.use(authInterceptor(getState().user.jwtToken));

        try {
            const res = await reportsAPI.get<ErrorReportStats>(`reports/errors?${query}`);
            dispatch({ type: ErrorReportActionTypes.FETCH_ERROR_REPORT_SUCCESS, payload: res.data.stats });
        } catch (e: any | AxiosError) {
            handleError(
                dispatch,
                e,
                ErrorReportActionTypes.FETCH_ERROR_REPORT_ERROR,
                'Error fetching error report',
            );
        }
    };
};
