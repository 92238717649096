import { ErrorReportState, ErrorReportAction, ErrorReportActionTypes } from '../types/errorReport';

const defaultState: ErrorReportState = {
    errorReportStats: [],
    loading: false,
    error: null,
};

const errorReportReducer = (state = defaultState, action: ErrorReportAction): ErrorReportState => {
    switch (action.type) {
    case ErrorReportActionTypes.FETCH_ERROR_REPORT_REQUEST:
        return { ...state, loading: true, error: null };
    case ErrorReportActionTypes.FETCH_ERROR_REPORT_SUCCESS:
        return { ...state, errorReportStats: action.payload, loading: false, error: null };
    case ErrorReportActionTypes.FETCH_ERROR_REPORT_ERROR:
        return { ...state, error: action.payload, loading: false };
    default:
        return state;
    }
};

export default errorReportReducer;
