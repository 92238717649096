import { AxiosError } from 'axios';
import queryString from 'query-string';
import { authInterceptor } from './interceptors';
import { handleError } from '../../helpers/error';
import { BidFloorStats, FetchBidFloorReportRequest } from '../../models/bidding';
import { BiddingReportAction, BiddingReportActionTypes } from '../types/bidding';
import { Action } from '../types/actions';

// eslint-disable-next-line
export const fetchBidFloorReport = (req: FetchBidFloorReportRequest): Action<BiddingReportAction> => {
    const { date, country, bundle, traffic_distribute_mode } = req;
    const query = queryString.stringify({ date, country, bundle, traffic_distribute_mode }, { arrayFormat: 'comma' });

    return async (dispatch, getState, { reportsAPI }) => {
        dispatch({ type: BiddingReportActionTypes.FETCH_BIDFLOOR_REPORT_REQUEST });

        reportsAPI.interceptors.request.use(authInterceptor(getState().user.jwtToken));

        try {
            const res = await reportsAPI.get<BidFloorStats>(`reports/bidding/bidfloor?${query}`);
            dispatch({ type: BiddingReportActionTypes.FETCH_BIDFLOOR_REPORT_SUCCESS, payload: res.data.stats });
        } catch (e: any | AxiosError) {
            handleError(
                dispatch,
                e,
                BiddingReportActionTypes.FETCH_BIDFLOOR_REPORT_ERROR,
                'Error fetching bidfloor report',
            );
        }
    };
};
