import { Country, FactWeight, Weight } from '../../models/country';

export interface CountryState {
    countries: Country[];
    weights: Weight[];
    factWeights: FactWeight[];
    loading: boolean;
    error: any;
}

export enum CountryActionTypes {
    FETCH_COUNTRIES_REQUEST = 'FETCH_COUNTRIES_REQUEST',
    FETCH_COUNTRIES_SUCCESS = 'FETCH_COUNTRIES_SUCCESS',
    FETCH_COUNTRIES_ERROR = 'FETCH_COUNTRIES_ERROR',
    FETCH_WEIGHTS_REQUEST = 'FETCH_WEIGHTS_REQUEST',
    FETCH_WEIGHTS_SUCCESS = 'FETCH_WEIGHTS_SUCCESS',
    FETCH_WEIGHTS_ERROR = 'FETCH_WEIGHTS_ERROR',
    UPDATE_WEIGHT_REQUEST = 'UPDATE_WEIGHT_REQUEST',
    UPDATE_WEIGHT_SUCCESS = 'UPDATE_WEIGHT_SUCCESS',
    UPDATE_WEIGHT_ERROR = 'UPDATE_WEIGHT_ERROR',
    ADD_COUNTRY_REQUEST = 'ADD_COUNTRY_REQUEST',
    ADD_COUNTRY_SUCCESS = 'ADD_COUNTRY_SUCCESS',
    ADD_COUNTRY_ERROR = 'ADD_COUNTRY_ERROR',
    FETCH_FACT_WEIGHTS_REQUEST = 'FETCH_FACT_WEIGHTS_REQUEST',
    FETCH_FACT_WEIGHTS_SUCCESS = 'FETCH_FACT_WEIGHTS_SUCCESS',
    FETCH_FACT_WEIGHTS_ERROR = 'FETCH_FACT_WEIGHTS_ERROR',
}

interface FetchCountriesRequestAction {
    type: CountryActionTypes.FETCH_COUNTRIES_REQUEST;
}

interface FetchCountriesSuccessAction {
    type: CountryActionTypes.FETCH_COUNTRIES_SUCCESS;
    payload: Country[];
}

interface FetchCountriesErrorAction {
    type: CountryActionTypes.FETCH_COUNTRIES_ERROR;
    payload: string;
}

interface FetchWeightsRequestAction {
    type: CountryActionTypes.FETCH_WEIGHTS_REQUEST;
}

interface FetchWeightsSuccessAction {
    type: CountryActionTypes.FETCH_WEIGHTS_SUCCESS;
    payload: Weight[];
}

interface FetchWeightsErrorAction {
    type: CountryActionTypes.FETCH_WEIGHTS_ERROR;
    payload: string;
}

interface UpdateWeightRequestAction {
    type: CountryActionTypes.UPDATE_WEIGHT_REQUEST;
}

interface UpdateWeightSuccessAction {
    type: CountryActionTypes.UPDATE_WEIGHT_SUCCESS;
    payload: Weight;
}

interface UpdateWeightErrorAction {
    type: CountryActionTypes.UPDATE_WEIGHT_ERROR;
    payload: string;
}

interface AddCountryRequestAction {
    type: CountryActionTypes.ADD_COUNTRY_REQUEST;
}

interface AddCountrySuccessAction {
    type: CountryActionTypes.ADD_COUNTRY_SUCCESS;
    payload: Weight;
}

interface AddCountryErrorAction {
    type: CountryActionTypes.ADD_COUNTRY_ERROR;
    payload: string;
}

interface FetchFactWeightsRequestAction {
    type: CountryActionTypes.FETCH_FACT_WEIGHTS_REQUEST;
}

interface FetchFactWeightsSuccessAction {
    type: CountryActionTypes.FETCH_FACT_WEIGHTS_SUCCESS;
    payload: FactWeight[];
}

interface FetchFactWeightsErrorAction {
    type: CountryActionTypes.FETCH_FACT_WEIGHTS_ERROR;
    payload: string;
}

export type CountryAction = FetchCountriesRequestAction |
    FetchCountriesSuccessAction |
    FetchCountriesErrorAction |
    FetchWeightsRequestAction |
    FetchWeightsSuccessAction |
    FetchWeightsErrorAction |
    UpdateWeightRequestAction |
    UpdateWeightSuccessAction |
    UpdateWeightErrorAction |
    AddCountryRequestAction |
    AddCountrySuccessAction |
    AddCountryErrorAction |
    FetchFactWeightsRequestAction |
    FetchFactWeightsSuccessAction |
    FetchFactWeightsErrorAction;
