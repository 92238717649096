import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { getCountryFullName, WeightKindMap } from '../../models/country';
import { withKeys } from '../../helpers/array';
import { compareDates, formatDate } from '../../helpers/date';
import { compareValues } from '../../helpers/compare';
import { BidFloorStat } from '../../models/bidding';

interface bidFloorReportColumn {
    key: string;
    date: string;
    bundle: string;
    country: string;
    traffic_distribute_mode: string;
    bid_floor: number;
    total: number;
}

interface BidFloorReportTableProps {
    loading: boolean;
    error: null | string;
    bidFloorStats: BidFloorStat[];
}

const BidFloorReportTable: React.FC<BidFloorReportTableProps> = props => {
    const { loading, bidFloorStats } = props;
    const defaultPageSize = 30;

    const columns: ColumnsType<bidFloorReportColumn> = [
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
            align: 'right',
            width: '10%',
            sorter: (a, b) => compareDates(a.date || '', b.date || ''),
            defaultSortOrder: 'ascend',
            render: date => <span>{formatDate(date)}</span>,
        },
        {
            title: 'Bundle',
            dataIndex: 'bundle',
            key: 'bundle',
            align: 'right',
            width: '10%',
            sorter: (a, b) => compareValues(a.bundle, b.bundle),
        },
        {
            title: 'Country',
            dataIndex: 'country',
            key: 'country',
            align: 'left',
            width: '10%',
            sorter: (a, b) => a.country.localeCompare(b.country),
            render: (country: string) => getCountryFullName(country),
        },
        {
            title: 'Traffic distribute mode',
            dataIndex: 'traffic_distribute_mode',
            key: 'traffic_distribute_mode',
            align: 'left',
            sorter: (a, b) => (
                a.traffic_distribute_mode.toString().localeCompare(b.traffic_distribute_mode.toString())
            ),
            render: (tdm: string): string => WeightKindMap[tdm],
        },
        {
            title: 'Bid floor',
            dataIndex: 'bid_floor',
            key: 'bid_floor',
            align: 'right',
            sorter: (a, b) => a.bid_floor - b.bid_floor,
        },
        {
            title: 'Total',
            dataIndex: 'total',
            key: 'total',
            align: 'right',
            sorter: (a, b) => a.total - b.total,
        },
    ];

    return (
        <Table
            className="bidfloorReport__table"
            style={{ width: '70%', margin: 'auto' }}
            bordered
            loading={loading}
            rowKey={record => record.key}
            dataSource={withKeys(bidFloorStats)}
            columns={columns}
            pagination={{ defaultPageSize }}
        />
    );
};

export default BidFloorReportTable;
