import { AxiosError } from 'axios';
import { CountryAction, CountryActionTypes } from '../types/country';
import { Country, FactWeight, Weight, WeightKind } from '../../models/country';
import { authInterceptor } from './interceptors';
import { handleError } from '../../helpers/error';
import { Action } from '../types/actions';

export const fetchCountries = (): Action<CountryAction> => (
    async (dispatch, getState, { defaultAPI }) => {
        dispatch({ type: CountryActionTypes.FETCH_COUNTRIES_REQUEST });

        defaultAPI.interceptors.request.use(authInterceptor(getState().user.jwtToken));

        try {
            const res = await defaultAPI.get<Country[]>('countries');
            dispatch({ type: CountryActionTypes.FETCH_COUNTRIES_SUCCESS, payload: res.data });
        } catch (e: any | AxiosError) {
            handleError(dispatch, e, CountryActionTypes.FETCH_COUNTRIES_ERROR, 'Error fetching countries');
        }
    }
);

export const fetchWeights = (kind: WeightKind): Action<CountryAction> => (
    async (dispatch, getState, { defaultAPI }) => {
        dispatch({ type: CountryActionTypes.FETCH_WEIGHTS_REQUEST });

        defaultAPI.interceptors.request.use(authInterceptor(getState().user.jwtToken));

        try {
            const res = await defaultAPI.get<Weight[]>(`countries/weights/${kind}`);
            dispatch({ type: CountryActionTypes.FETCH_WEIGHTS_SUCCESS, payload: res.data });
        } catch (e: any | AxiosError) {
            handleError(dispatch, e, CountryActionTypes.FETCH_WEIGHTS_ERROR, 'Error fetching weight');
        }
    }
);

export const updateWeight = (weight: Weight): Action<CountryAction> => (
    async (dispatch, getState, { defaultAPI }) => {
        dispatch({ type: CountryActionTypes.UPDATE_WEIGHT_REQUEST });

        defaultAPI.interceptors.request.use(authInterceptor(getState().user.jwtToken));
        const weightKind = weight.weight_kind;

        try {
            const res = await defaultAPI.put<Weight>(`countries/weights/${weightKind}/${weight.country}`, weight);
            dispatch({ type: CountryActionTypes.UPDATE_WEIGHT_SUCCESS, payload: res.data });
        } catch (e: any | AxiosError) {
            handleError(dispatch, e, CountryActionTypes.UPDATE_WEIGHT_ERROR, `Error updating ${weightKind} weight`);
        }
    }
);

export const addNewCountry = (newCountry: Weight): Action<CountryAction> => (
    async (dispatch, getState, { defaultAPI }) => {
        dispatch({ type: CountryActionTypes.ADD_COUNTRY_REQUEST });

        defaultAPI.interceptors.request.use(authInterceptor(getState().user.jwtToken));

        const { country, weight_kind: weightKind } = newCountry;

        try {
            const res = await defaultAPI.post<Weight>(`countries/weights/${weightKind}/${country}`, newCountry);
            dispatch({ type: CountryActionTypes.ADD_COUNTRY_SUCCESS, payload: res.data });
        } catch (e: any | AxiosError) {
            handleError(dispatch, e, CountryActionTypes.ADD_COUNTRY_ERROR, `Error adding ${country} country`);
        }
    }
);

export const fetchFactWeights = (kind: WeightKind, date: string): Action<CountryAction> => (
    async (dispatch, getState, { reportsAPI }) => {
        dispatch({ type: CountryActionTypes.FETCH_FACT_WEIGHTS_REQUEST });

        reportsAPI.interceptors.request.use(authInterceptor(getState().user.jwtToken));

        try {
            const res = await reportsAPI.get<FactWeight[]>(`countries/fact-weights/${kind}?date=${date}`);
            dispatch({ type: CountryActionTypes.FETCH_FACT_WEIGHTS_SUCCESS, payload: res.data });
        } catch (e: any) {
            handleError(dispatch, e, CountryActionTypes.FETCH_FACT_WEIGHTS_ERROR, 'Error fetching fact weights');
        }
    }
);
