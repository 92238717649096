import { useState } from 'react';
import { Dispatch } from 'redux';
import { Button, DatePicker, DatePickerProps, Select, Typography, Row, Col } from 'antd';
import dayjs from 'dayjs';
import type { CheckboxValueType } from 'antd/es/checkbox/Group';
import { LURLReportAction } from '../../store/types/lurl';
import { RootState } from '../../store/reducers';
import { formatDate } from '../../helpers/date';
import { FetchLURLReportRequest, LURLReason, LURLStat } from '../../models/lurl';
import { Country } from '../../models/country';
import CountrySelect from '../common/CountrySelect';
import TDMSelect from '../common/TDMSelect';
import BundleSelect from '../common/BundleSelect';
import ProviderSelect from '../common/ProviderSelect';
import { ProviderListItem } from '../../models/provider';
import LURLReasonSelect from '../common/LURLReasonSelect';
import XLSDownload from '../common/XLSXDownload';
import ThunkArguments from '../../store/types/common';
import ZoneSelect from '../common/ZoneSelect';

const { Text } = Typography;
const { Option } = Select;

interface LURLReportBarProps {
    fetchLurlReport: (req: FetchLURLReportRequest)
        => (dispatch: Dispatch<LURLReportAction>,
            getState: () => RootState, args: ThunkArguments) => Promise<void>;
    lurlStats: LURLStat[];
    lurlReasons: LURLReason[];
    providersList: ProviderListItem[];
    countries: Country[];
    groupByList: string[];
    groupByOptions: Record<string, string>;
    onGroupByChange: (checkedValues: CheckboxValueType[]) => void;
}

const dateFormat = 'YYYY-MM-DD';
const maxTimeDifference = 7776000000;

const LURLReportBar: React.FC<LURLReportBarProps> = props => {
    const { fetchLurlReport, lurlStats, lurlReasons, providersList, countries, groupByOptions,
        onGroupByChange, groupByList } = props;

    const getDefaultFrom = () => {
        const date = new Date();
        date.setDate(date.getDate() - 1);
        return formatDate(date.toString());
    };

    const getDefaultTo = () => {
        const date = new Date();
        return formatDate(date.toString());
    };

    const [from, setFrom] = useState(getDefaultFrom());
    const [to, setTo] = useState(getDefaultTo());
    const [checkedValues, setCheckedValues] = useState<CheckboxValueType[]>([]);
    const [bundle, setBundle] = useState<string | undefined>(undefined);
    const [country, setCountry] = useState<string | undefined>(undefined);
    const [zone, setZone] = useState<string | undefined>(undefined);
    const [provider, setProvider] = useState<string | undefined>(undefined);
    const [tdm, setTDM] = useState<string | undefined>(undefined);
    const [lurlReason, setLURLReason] = useState<string | undefined>(undefined);
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const onFromChange: DatePickerProps['onChange'] = (date, dateString) => {
        setFrom(dateString);
    };

    const onToChange: DatePickerProps['onChange'] = (date, dateString) => {
        setTo(dateString);
    };

    const bundleChange = (value: string) => setBundle(value);
    const tdmChange = (value: string) => setTDM(value);

    const search = () => {
        setShowErrorMessage(false);
        const fromTime = new Date(from).getTime();
        const toTime = new Date(to).getTime();
        const diff = toTime - fromTime;
        if (diff > maxTimeDifference) {
            setShowErrorMessage(true);
            setErrorMessage('Time range must be lower than 90 days!');
            return;
        }
        onGroupByChange(checkedValues);
        const req: FetchLURLReportRequest = {
            from,
            to,
            provider,
            bundle,
            country,
            zone,
            tdm,
            lurlReason,
            groupBy: checkedValues.map(item => item.toString()),
        };
        fetchLurlReport(req);
    };

    const renderGroupBy = () => (
        <Select
            mode="multiple"
            allowClear
            style={{ width: '300px' }}
            onChange={setCheckedValues}
        >
            {[...Object.keys(groupByOptions)]
                .sort((a, b) => a.localeCompare(b))
                .map(key => <Option key={key} value={key}>{groupByOptions[key]}</Option>)}
        </Select>
    );

    return (
        <>
            <Row
                gutter={10}
                justify="start"
                align="middle"
                wrap={false}
                style={{ width: '80%', margin: 'auto', marginBottom: 16 }}
            >
                <Col>
                    <Text>From: </Text><DatePicker onChange={onFromChange} defaultValue={dayjs(getDefaultFrom(), dateFormat)} />
                </Col>
                <Col>
                    <Text>To: </Text><DatePicker onChange={onToChange} defaultValue={dayjs(getDefaultTo(), dateFormat)} />
                </Col>
                <Col>
                    <Text>Bundle: </Text><BundleSelect onChange={bundleChange} />
                </Col>
                <Col>
                    {providersList.length > 0 && <ProviderSelect providers={providersList} onChange={setProvider} />}
                </Col>
            </Row>
            <Row
                gutter={10}
                justify="start"
                align="middle"
                wrap={false}
                style={{ width: '80%', margin: 'auto', marginBottom: 16 }}
            >
                <Col>
                    <Text>Traffic distribute mode: </Text><TDMSelect value={tdm} onChange={tdmChange} />
                </Col>
                <Col>
                    <CountrySelect countries={countries} onChange={setCountry} />
                </Col>
                <Col>
                    <Text>Zone:</Text> <ZoneSelect value={zone} onChange={setZone} />
                </Col>
                <Col flex="auto">
                    {lurlReasons.length > 0 && <LURLReasonSelect reasons={lurlReasons} onChange={setLURLReason} />}
                </Col>
            </Row>
            <Row
                gutter={10}
                justify="start"
                align="middle"
                wrap={false}
                style={{ width: '80%', margin: 'auto', marginBottom: 16 }}
            >
                <Col>
                    <Text>Breakdowns: </Text>{renderGroupBy()}
                </Col>
            </Row>
            <Row
                gutter={10}
                justify="start"
                align="middle"
                wrap={false}
                style={{ width: '80%', margin: 'auto', marginBottom: 16 }}
            >
                <Col>
                    <Button onClick={search} type="primary">Search</Button>
                </Col>
                <Col>
                    {lurlStats.length > 0 && (
                        <XLSDownload
                            data={lurlStats}
                            filename="performance_report.xlsx"
                            breakdownOptions={Object.keys(groupByOptions)}
                            breakdowns={groupByList}
                        />
                    )}
                </Col>
                <Col>
                    {showErrorMessage && <Text style={{ color: 'red' }}>{errorMessage}</Text>}
                </Col>
            </Row>
        </>
    );
};

export default LURLReportBar;
