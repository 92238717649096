import { IncomingReportState, IncomingReportAction, IncomingReportActionTypes } from '../types/incoming';

const defaultState: IncomingReportState = {
    incomingInfoStats: [],
    loading: false,
    error: null,
};

const incomingReportReducer = (state = defaultState, action: IncomingReportAction): IncomingReportState => {
    switch (action.type) {
    case IncomingReportActionTypes.FETCH_INCOMING_REPORT_REQUEST:
        return { ...state, loading: true, error: null };
    case IncomingReportActionTypes.FETCH_INCOMING_REPORT_SUCCESS:
        return { ...state, incomingInfoStats: action.payload, loading: false, error: null };
    case IncomingReportActionTypes.FETCH_INCOMING_REPORT_ERROR:
        return { ...state, error: action.payload, loading: false };
    default:
        return state;
    }
};

export default incomingReportReducer;
