import { Button, Space, Tooltip } from 'antd';
import { CloudDownloadOutlined } from '@ant-design/icons';
import * as XLSX from 'xlsx';
import { IncomingInfo } from '../../models/incoming';

interface IncomingReportCSVProps {
    incomingStats: IncomingInfo[];
}

const IncomingReportCSV: React.FC<IncomingReportCSVProps> = props => {
    const { incomingStats } = props;

    const generateCSVString = (data: IncomingInfo[]) => {
        const content: string[][] = [];
        content.push(['Country code', 'Provider', 'RP Title', 'Image URL', 'Link', 'Date']);

        const sortedIncoming = [...data].sort((a, b) => {
            const aLower = a.country_code.toLowerCase();
            const bLower = b.country_code.toLowerCase();
            if (aLower < bLower) return -1;
            if (aLower > bLower) return 1;
            return 0;
        });

        sortedIncoming.forEach(incoming => {
            content.push([incoming.country_code, incoming.provider, incoming.rp_title, incoming.img_url,
                incoming.link, incoming.date]);
        });

        return content;
    };

    const downloadXLS = (data: IncomingInfo[]) => {
        const content = generateCSVString(data);
        const ws = XLSX.utils.aoa_to_sheet(content);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'sheet');
        XLSX.writeFileXLSX(wb, 'incoming_report.xlsx');
    };

    return (
        <Space style={{ width: '70%', margin: 'auto', marginBottom: 16, display: 'flex' }}>
            {incomingStats && incomingStats.length > 0
            && (
                <Tooltip title="Download XLS">
                    <Button onClick={() => downloadXLS(incomingStats)} icon={<CloudDownloadOutlined />} />
                </Tooltip>
            )}
        </Space>
    );
};

export default IncomingReportCSV;
