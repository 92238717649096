import React, { useRef } from 'react';
import { Select } from 'antd';
import { BaseSelectRef } from 'rc-select';
import { LURLReason, sortReasons } from '../../models/lurl';

const { Option } = Select;

interface LURLReasonSelectProps {
    reasons: LURLReason[];
    onSelect?: (value: string) => void;
    value?: string;
    onChange?: (value: string) => void;
}

const LURLReasonSelect: React.FC<LURLReasonSelectProps> = props => {
    const { reasons, onSelect, value, onChange } = props;

    const selectEl = useRef<BaseSelectRef>(null);

    const onLURLReasonSelect = (e: string) => {
        selectEl.current!.blur();
        if (onSelect) {
            onSelect(e);
        }
    };

    const renderOption = (r: LURLReason) => (
        <Option
            key={r.reason}
            value={r.reason}
            style={{ fontSize: '0.8rem' }}
        >
            {r.reason} - {r.error_reason}
        </Option>
    );

    return (
        <Select
            showSearch
            value={value}
            defaultValue={undefined}
            ref={selectEl}
            optionFilterProp="children"
            placeholder="Select LURL reason"
            onSelect={onLURLReasonSelect}
            onChange={onChange}
            allowClear
            style={{ width: '100%' }}
        >
            {[...reasons].sort(sortReasons)
                .map(renderOption)}
        </Select>
    );
};

export default LURLReasonSelect;
