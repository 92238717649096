import { combineReducers } from 'redux';
import bundleReducer from './bundleReducer';
import defaultPriceReducer from './defaultPriceReducer';
import userReducer from './userReducer';
import countryReducer from './countryReducer';
import providerReducer from './providerReducer';
import lurlReportReducer from './lurlReportReducer';
import adblockReportReducer from './adblockReportReducer';
import incomingReportReducer from './incomingReportReducer';
import errorReportReducer from './errorReportReducer';
import performanceReportReducer from './performanceReportReducer';
import biddingReportReducer from './biddingReportReducer';

export const rootReducer = combineReducers({
    bundle: bundleReducer,
    defaultPrice: defaultPriceReducer,
    user: userReducer,
    country: countryReducer,
    provider: providerReducer,
    lurlReport: lurlReportReducer,
    adblockReport: adblockReportReducer,
    incomingReport: incomingReportReducer,
    errorReport: errorReportReducer,
    performanceReport: performanceReportReducer,
    biddingReport: biddingReportReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
