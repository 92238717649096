import { Performance } from '../../models/performance';

export interface PerformanceReportState {
    performanceStats: Performance[];
    loading: boolean;
    error: null | string;
}

export enum PerformanceReportActionTypes {
    FETCH_PERFORMANCE_REPORT_REQUEST = 'FETCH_PERFORMANCE_REPORT_REQUEST',
    FETCH_PERFORMANCE_REPORT_SUCCESS = 'FETCH_PERFORMANCE_REPORT_SUCCESS',
    FETCH_PERFORMANCE_REPORT_ERROR = 'FETCH_PERFORMANCE_REPORT_ERROR',
}

interface FetchPerformanceReportRequestAction {
    type: PerformanceReportActionTypes.FETCH_PERFORMANCE_REPORT_REQUEST;
}

interface FetchPerformanceReportSuccessAction {
    type: PerformanceReportActionTypes.FETCH_PERFORMANCE_REPORT_SUCCESS;
    payload: Performance[];
}

interface FetchPerformanceReportErrorAction {
    type: PerformanceReportActionTypes.FETCH_PERFORMANCE_REPORT_ERROR;
    payload: string;
}

export type PerformanceReportAction = FetchPerformanceReportRequestAction |
    FetchPerformanceReportSuccessAction |
    FetchPerformanceReportErrorAction;
