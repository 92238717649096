export interface LoginValues {
    username: string;
    password: string;
}

export enum UserGroups {
    ROLE_ADMIN = 'ROLE_ADMIN',
    ROLE_USER = 'ROLE_USER',
    ROLE_WEIGHT_BIDDING_WRITER = 'ROLE_WEIGHT_BIDDING_WRITER',
    ROLE_WEIGHT_BIDDING_INTERNAL_WRITER = 'ROLE_WEIGHT_BIDDING_INTERNAL_WRITER',
    ROLE_WEIGHT_REV_SHARE_WRITER = 'ROLE_WEIGHT_REV_SHARE_WRITER',
    ROLE_WEIGHT_DSP_REV_SHARE_WRITER = 'ROLE_WEIGHT_DSP_REV_SHARE_WRITER',
    ROLE_BUNDLE_WRITER = 'ROLE_BUNDLE_WRITER',
    ROLE_ADBLOCK_DELETER = 'ROLE_ADBLOCK_DELETER',
}

export const isAdmin = (groups: UserGroups[]) => groups.includes(UserGroups.ROLE_ADMIN);
export const isUser = (groups: UserGroups[]) => groups.includes(UserGroups.ROLE_USER);
export const isBiddingWriter = (groups: UserGroups[]) => groups.includes(UserGroups.ROLE_WEIGHT_BIDDING_WRITER);
export const isBiddingInternalWriter = (groups: UserGroups[]) => groups.includes(UserGroups.ROLE_WEIGHT_BIDDING_INTERNAL_WRITER);
export const isRevShareWriter = (groups: UserGroups[]) => groups.includes(UserGroups.ROLE_WEIGHT_REV_SHARE_WRITER);
export const isDspRevShareWriter = (groups: UserGroups[]) => groups.includes(UserGroups.ROLE_WEIGHT_DSP_REV_SHARE_WRITER);
export const isBundleWriter = (groups: UserGroups[]) => groups.includes(UserGroups.ROLE_BUNDLE_WRITER);
export const isAdblockDeleter = (groups: UserGroups[]) => groups.includes(UserGroups.ROLE_ADBLOCK_DELETER);
