import { BidFloorStat } from '../../models/bidding';

export interface BiddingReportState {
    bidFloorStats: BidFloorStat[];
    loading: boolean;
    error: null | string;
}

export enum BiddingReportActionTypes {
    FETCH_BIDFLOOR_REPORT_REQUEST = 'FETCH_BIDFLOOR_REPORT_REQUEST',
    FETCH_BIDFLOOR_REPORT_SUCCESS = 'FETCH_BIDFLOOR_REPORT_SUCCESS',
    FETCH_BIDFLOOR_REPORT_ERROR = 'FETCH_BIDFLOOR_REPORT_ERROR',
}

interface FetchBidfloorReportRequestAction {
    type: BiddingReportActionTypes.FETCH_BIDFLOOR_REPORT_REQUEST;
}

interface FetchBidfloorReportSuccessAction {
    type: BiddingReportActionTypes.FETCH_BIDFLOOR_REPORT_SUCCESS;
    payload: BidFloorStat[];
}

interface FetchBidfloorReportErrorAction {
    type: BiddingReportActionTypes.FETCH_BIDFLOOR_REPORT_ERROR;
    payload: string;
}

export type BiddingReportAction = FetchBidfloorReportRequestAction |
    FetchBidfloorReportSuccessAction |
    FetchBidfloorReportErrorAction;
