import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { Country, getCountryFullName } from '../../models/country';
import { UserGroups } from '../../models/user';
import { withKeys } from '../../helpers/array';
import { IncomingInfo } from '../../models/incoming';
import { compareDates, formatDate } from '../../helpers/date';

interface incomingReportColumn {
    key: string;
    date: string;
    country_code: string;
    provider: string;
    rp_title: string;
    img_url: string;
    link: string;
}

interface IncomingReportTableProps {
    loading: boolean;
    error: null | string;
    incomingStats: IncomingInfo[];
    countriesList: Country[];
    groups: UserGroups[];
}

const IncomingReportTable: React.FC<IncomingReportTableProps> = props => {
    const { loading, incomingStats, countriesList } = props;
    const defaultPageSize = 30;

    const truncate = (s: string) => s.length > 30 ? `${s.substring(0, 27)}...` : s;

    const columns: ColumnsType<incomingReportColumn> = [
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
            align: 'right',
            width: '10%',
            sorter: (a, b) => compareDates(a.date || '', b.date || ''),
            defaultSortOrder: 'ascend',
            render: date => <span>{formatDate(date)}</span>,
        },
        {
            title: 'Country Name',
            dataIndex: 'country_code',
            key: 'country_code',
            align: 'left',
            width: '10%',
            sorter: (a, b) => a.country_code.localeCompare(b.country_code),
            render: (country_code: string) => getCountryFullName(country_code),
        },
        {
            title: 'Provider',
            dataIndex: 'provider',
            key: 'provider',
            align: 'right',
            width: '10%',
            sorter: (a, b) => a.provider.localeCompare(b.provider),
        },
        {
            title: 'RP Title',
            dataIndex: 'rp_title',
            key: 'rp_title',
            align: 'right',
            width: '10%',
        },
        {
            title: 'Image URL',
            dataIndex: 'img_url',
            key: 'img_url',
            align: 'right',
            width: '30%',
            render: text => <a href={text} target="_blank" rel="noreferrer">{truncate(text)}</a>,
        },
        {
            title: 'Link',
            dataIndex: 'link',
            key: 'link',
            align: 'right',
            width: '30%',
            render: text => <a href={text} target="_blank" rel="noreferrer">{truncate(text)}</a>,
        },
    ];

    return (
        <Table
            className="incomingReport__table"
            style={{ width: '70%', margin: 'auto' }}
            bordered
            loading={loading}
            rowKey={record => record.key}
            dataSource={withKeys(incomingStats)}
            columns={columns}
            pagination={{ defaultPageSize }}
        />
    );
};

export default IncomingReportTable;
