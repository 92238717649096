import React, { useRef } from 'react';
import { Select } from 'antd';
import { BaseSelectRef } from 'rc-select';

const { Option } = Select;
const statusCodes = ['200', '204'];

interface StatusCodeSelectProps {
    onSelect?: (value: string) => void;
    value?: string;
    onChange?: (value: string) => void;
}

const StatusCodeSelect: React.FC<StatusCodeSelectProps> = props => {
    const { onSelect, value, onChange } = props;

    const selectEl = useRef<BaseSelectRef>(null);

    const onTDMSelect = (e: string) => {
        selectEl.current!.blur();
        if (onSelect) {
            onSelect(e);
        }
    };

    const renderOption = (code: string) => (
        <Option
            key={code}
            value={code}
        >
            {code}
        </Option>
    );

    return (
        <Select
            value={value}
            defaultValue={undefined}
            ref={selectEl}
            style={{ width: 80 }}
            optionFilterProp="children"
            placeholder="Select status code"
            onSelect={onTDMSelect}
            onChange={onChange}
            allowClear
        >
            {statusCodes.map(renderOption)}
        </Select>
    );
};

export default StatusCodeSelect;
