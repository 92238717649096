import { notification } from 'antd';
import { CheckCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons';

const color = '#108ee9';

const defaultNotify = (message: string, description: string) => {
    notification.open({
        message,
        description,
        icon: <CheckCircleOutlined style={{ color }} />,
    });
};

const errorNotify = (message: string, description: string) => {
    notification.open({
        message,
        description,
        icon: <ExclamationCircleOutlined style={{ color }} />,
    });
};

export {
    defaultNotify,
    errorNotify,
};
