import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { Country, getCountryFullName, Weight, WeightKind } from '../../models/country';
import { ProviderListItem } from '../../models/provider';
import {
    isAdmin,
    isBiddingInternalWriter,
    isBiddingWriter,
    isDspRevShareWriter,
    isRevShareWriter,
    UserGroups,
} from '../../models/user';
import WeightMapActions from './WeightMapActions';
import { UpdateAction } from '../../store/types/actions';
import styles from './WeightMap.module.scss';
import { compareDates, formatDateTime } from '../../helpers/date';
import { compareValues } from '../../helpers/compare';

interface weightMapColumn {
    country: string;
    countryCode: string;
    bid_floor_price_scale: number;
    provider_price_scale: number;
    active: boolean;
    updated_at: string;
    updated_by: string;
}

interface WeightMapTableProps {
    loading: boolean;
    error: null | string;
    weights: Weight[];
    countriesList: Country[];
    providersList: ProviderListItem[];
    groups: UserGroups[];
    updateWeight: UpdateAction<Weight, void>;
    kind: WeightKind;
}

const WeightMapTable: React.FC<WeightMapTableProps> = props => {
    const { loading, error, weights, providersList, groups, updateWeight, kind } = props;

    const isBiddingKind = () => kind === WeightKind.Bidding || kind === WeightKind.BiddingInternal;

    const getWeightMap = (): weightMapColumn[] => (
        weights.map(weight => {
            const providerWeights = weight.weights;
            const { active, updated_by, country, bid_floor_price_scale, provider_price_scale } = weight;
            return {
                country: getCountryFullName(country),
                countryCode: country,
                active,
                bid_floor_price_scale,
                provider_price_scale,
                updated_at: weight.updated_at || '',
                updated_by,
                ...providerWeights,
            };
        })
    );

    const getProvidersColumns = (): ColumnsType<weightMapColumn> => (
        providersList.map(({ provider_name }) => ({
            title: provider_name,
            dataIndex: provider_name,
            key: provider_name,
            align: 'right',
            width: 50,
            render: providerName => providerName ? `${providerName}%` : '',
        }
        ))
    );

    const getScrollX = () => 50 * providersList.length + (isBiddingKind() ? 1350 : 1050);

    const getPriceScaleColumns = (): ColumnsType<weightMapColumn> => {
        if (isBiddingKind()) {
            return [
                {
                    title: 'Bid Floor price scale',
                    dataIndex: 'bid_floor_price_scale',
                    key: 'bid_floor_price_scale',
                    align: 'right',
                    width: 100,
                    sorter: (a, b) => compareValues(a.bid_floor_price_scale, b.bid_floor_price_scale),
                    render: providerName => providerName ? `${providerName}%` : '',
                },
                {
                    title: 'Provider price scale',
                    dataIndex: 'provider_price_scale',
                    key: 'provider_price_scale',
                    align: 'right',
                    width: 100,
                    sorter: (a, b) => compareValues(a.provider_price_scale, b.provider_price_scale),
                    render: providerName => providerName ? `${providerName}%` : '',
                },
            ];
        }
        return [];
    };

    const isAllowedUser = (): boolean => {
        if (isAdmin(groups)) return true;
        if (kind === WeightKind.RevShare) return isRevShareWriter(groups);
        if (kind === WeightKind.Bidding) return isBiddingWriter(groups);
        if (kind === WeightKind.BiddingInternal) return isBiddingInternalWriter(groups);
        if (kind === WeightKind.DSPRevShare) return isDspRevShareWriter(groups);
        return false;
    };

    const renderActions = (record: any) => {
        const weight = weights.find(w => w.country === record.countryCode);
        return isAllowedUser() && weight
            ? (
                <WeightMapActions
                    countryName={record.country}
                    weight={weight}
                    error={error}
                    providersList={providersList}
                    updateWeight={updateWeight}
                    kind={kind}
                />
            ) : null;
    };

    const columns: ColumnsType<weightMapColumn> = [
        {
            title: 'Country Name',
            dataIndex: 'country',
            key: 'country',
            width: 80,
            align: 'left',
            fixed: 'left',
            sorter: (a, b) => a.country.localeCompare(b.country),
            defaultSortOrder: 'ascend',
        },
        ...getProvidersColumns(),
        ...getPriceScaleColumns(),
        {
            title: 'Updated at',
            dataIndex: 'updated_at',
            key: 'updatedAt',
            align: 'right',
            width: 75,
            render: updatedAt => <span style={{ whiteSpace: 'nowrap', fontSize: 13 }}>{formatDateTime(updatedAt)}</span>,
            sorter: (a, b) => compareDates(a.updated_at || '', b.updated_at || ''),
        },
        {
            title: 'Updated by',
            dataIndex: 'updated_by',
            key: 'updatedBy',
            align: 'right',
            width: 90,
            sorter: (a, b) => a.updated_by.localeCompare(b.updated_by),
            render: updatedBy => <span style={{ whiteSpace: 'nowrap', fontSize: 13 }}>{updatedBy}</span>,
        },
        {
            title: 'Action',
            key: 'action',
            align: 'center',
            width: 50,
            render: renderActions,
        },
    ];

    return (
        <Table
            className="weightmap__table"
            rowClassName={record => !record.active ? styles.table__row_disabled : ''}
            style={{ width: '100%', margin: 'auto' }}
            bordered
            loading={loading}
            rowKey={record => record.country}
            dataSource={getWeightMap()}
            columns={columns}
            pagination={false}
            size="small"
            scroll={{ x: getScrollX(), y: '69vh' }}
        />
    );
};

export default WeightMapTable;
