import React, { useRef } from 'react';
import { Select } from 'antd';
import { BaseSelectRef } from 'rc-select';
import { Truncate } from '../../models/truncate';

const { Option } = Select;

interface TruncateSelectProps {
    onSelect?: (value: string) => void;
    value?: string;
    onChange?: (value: string) => void;
}

const TruncateSelect: React.FC<TruncateSelectProps> = props => {
    const { onSelect, value, onChange } = props;

    const selectEl = useRef<BaseSelectRef>(null);

    const onTruncateSelect = (e: string) => {
        selectEl.current!.blur();
        if (onSelect) {
            onSelect(e);
        }
    };

    return (
        <Select
            value={value}
            defaultValue={value}
            ref={selectEl}
            style={{ width: 120 }}
            optionFilterProp="children"
            placeholder="Select truncate"
            onSelect={onTruncateSelect}
            onChange={onChange}
            allowClear
        >
            {[...Object.values(Truncate)].map(item => <Option key={item} value={item}>{item}</Option>)}
        </Select>
    );
};

export default TruncateSelect;
