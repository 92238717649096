import { DefaultPriceAction, DefaultPriceActionTypes } from '../types/defaultPrice';
import { DefaultPrice } from '../../models/price';
import { authInterceptor } from './interceptors';
import { handleError } from '../../helpers/error';
import { Action } from '../types/actions';

export const fetchDefaultPrice = (): Action<DefaultPriceAction> => (
    async (dispatch, getState, { defaultAPI }) => {
        defaultAPI.interceptors.request.use(authInterceptor(getState().user.jwtToken));

        try {
            const res = await defaultAPI.get<DefaultPrice>('third-party-apps/default-price');
            dispatch({ type: DefaultPriceActionTypes.FETCH_DEFAULT_PRICE, payload: res.data });
        } catch (e: any) {
            handleError(dispatch, e, DefaultPriceActionTypes.FETCH_DEFAULT_PRICE_ERROR, 'Error fetching default price');
        }
    }
);

export const changeDefaultPrice = (defaultPrice: DefaultPrice): Action<DefaultPriceAction> => (
    async (dispatch, getState, { defaultAPI }) => {
        defaultAPI.interceptors.request.use(authInterceptor(getState().user.jwtToken));

        try {
            await defaultAPI.post<DefaultPrice>('third-party-apps/default-price', defaultPrice);
            dispatch({ type: DefaultPriceActionTypes.CHANGE_DEFAULT_PRICE, payload: defaultPrice });
        } catch (e: any) {
            handleError(dispatch, e, DefaultPriceActionTypes.CHANGE_DEFAULT_PRICE_ERROR, 'Error change default price');
        }
    }
);
