import { useState } from 'react';
import { Button, Tooltip, Row, Col } from 'antd';
import { CloudDownloadOutlined } from '@ant-design/icons';
import * as XLSX from 'xlsx';
import AddBundle from '../modals/AddBundle';
import Bundle from '../../models/bundle';
import { DefaultPrice } from '../../models/price';
import { Country } from '../../models/country';
import { CreateAction } from '../../store/types/actions';
import { isAdmin, isBundleWriter, UserGroups } from '../../models/user';

interface BundlesBarProps {
    bundles: Bundle[];
    defaultPrice: DefaultPrice;
    countries: Country[];
    error: null | string;
    groups: UserGroups[];
    addBundle: CreateAction<Bundle, void>;
}

const BundlesBar: React.FC<BundlesBarProps> = ({ bundles, defaultPrice, countries, error, groups, addBundle }) => {
    const [showAddModal, setShowAddModal] = useState(false);
    const [bundlesNamesList, setBundlesNamesList] = useState<string[]>([]);

    const generateCSVString = (data: Bundle[]) => {
        const content: string[][] = [];
        content.push(['Bundle Name', 'URL', 'GP URL', 'Price']);

        const sortedBundles = [...data].sort((a, b) => {
            const aLower = a.name.toLowerCase();
            const bLower = b.name.toLowerCase();
            if (aLower < bLower) return -1;
            if (aLower > bLower) return 1;
            return 0;
        });

        sortedBundles.forEach(bundle => {
            content.push([bundle.name, bundle.url, bundle.gp_url, bundle.price.toString()]);
            bundle.price_by_country?.forEach(countryPrice => {
                content.push([bundle.name, countryPrice.country, '', countryPrice.price.toString()]);
            });
        });

        return content;
    };

    const downloadXLS = (data: Bundle[]) => {
        const content = generateCSVString(data);
        const ws = XLSX.utils.aoa_to_sheet(content);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'sheet');
        XLSX.writeFileXLSX(wb, 'third_party_apps.xlsx');
    };

    const handleAdd = () => {
        setBundlesNamesList(bundles.map(bundle => bundle.name));
        setShowAddModal(true);
    };

    const isAllowedUser = (): boolean => {
        if (isAdmin(groups)) return true;
        return isBundleWriter(groups);
    };

    return (
        <Row gutter={10} justify="start" align="middle" wrap={false} style={{ margin: 'auto', marginBottom: 16 }}>
            {isAllowedUser()
            && (
                <Col>
                    <Button onClick={handleAdd} type="primary">Add bundle</Button>
                </Col>
            )}
            <Tooltip title="Download XLS">
                <Button onClick={() => downloadXLS(bundles)} icon={<CloudDownloadOutlined />} />
            </Tooltip>
            <AddBundle
                open={showAddModal}
                setOpen={setShowAddModal}
                bundlesNamesList={bundlesNamesList}
                defaultPrice={defaultPrice}
                countries={countries}
                error={error}
                addBundle={addBundle}
            />
        </Row>
    );
};

export default BundlesBar;
