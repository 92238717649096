import { useState } from 'react';
import { Modal, Form, Button, notification } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';
import CountryWeightForm from '../forms/CountryWeightForm';
import { ProviderListItem } from '../../models/provider';
import { Weight, WeightKind } from '../../models/country';
import { UpdateAction } from '../../store/types/actions';

interface EditWeightModalProps {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    countryName: string;
    weight: Weight;
    error: null | string;
    providersList: ProviderListItem[];
    updateWeight: UpdateAction<Weight, void>;
    kind: WeightKind;
}

const EditWeightModal: React.FC<EditWeightModalProps> = props => {
    const { open, setOpen, error, countryName, weight, providersList, updateWeight, kind } = props;
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [form] = Form.useForm();

    // eslint-disable-next-line
    const getSupportingProviders = (): ProviderListItem[] => {
        return providersList.filter(provider => provider.allowed_countries.includes(weight.country));
    };

    const handleOk = async (values: Record<string, any>) => {
        const { weight_kind, country, updated_by } = weight;
        const { active, weights, bid_floor_price_scale, provider_price_scale } = values;
        const updatedWeight: Weight = {
            weight_kind,
            country,
            active,
            weights,
            bid_floor_price_scale,
            provider_price_scale,
            updated_by,
        };
        setConfirmLoading(true);
        console.log('updated weight: ', updatedWeight);
        await updateWeight(updatedWeight);
        setConfirmLoading(false);
        console.log('error: ', error);
        if (!error) {
            setOpen(false);
            notification.open({
                message: `${countryName} updated`,
                icon: <CheckCircleOutlined style={{ color: '#108ee9' }} />,
            });
        }
    };

    const handleCancel = () => setOpen(false);

    const getTitle = (weightKind: WeightKind, country: string): string => {
        if (weightKind === WeightKind.RevShare) return `Edit providers weight for ${country}`;
        if (weightKind === WeightKind.Bidding) return `Edit providers bidding weight for ${country}`;
        if (weightKind === WeightKind.BiddingInternal) return `Edit providers bidding internal weight for ${country}`;
        return `Edit providers DSP weight for ${country}`;
    };

    return (
        <Modal
            title={getTitle(kind, countryName)}
            getContainer={false}
            open={open}
            confirmLoading={confirmLoading}
            onCancel={handleCancel}
            footer={[
                <Button key="cancel" onClick={handleCancel}>
                    Cancel
                </Button>,
                <Button form={weight.country} key="submit" htmlType="submit" type="primary" loading={confirmLoading}>
                    Submit
                </Button>,
            ]}
        >
            <CountryWeightForm
                form={form}
                weight={weight}
                providersList={getSupportingProviders()}
                onFinish={handleOk}
            />
        </Modal>
    );
};

export default EditWeightModal;
