import { Provider, ProviderListItem } from '../../models/provider';

export interface ProviderState {
    providers: Provider[];
    providersList: ProviderListItem[];
    loading: boolean;
    error: any;
}

export enum ProviderActionTypes {
    FETCH_PROVIDER_REQUEST = 'FETCH_PROVIDER_REQUEST',
    FETCH_PROVIDER_SUCCESS = 'FETCH_PROVIDER_SUCCESS',
    FETCH_PROVIDER_ERROR = 'FETCH_PROVIDER_ERROR',
    FETCH_PROVIDERS_LIST_REQUEST = 'FETCH_PROVIDERS_LIST_REQUEST',
    FETCH_PROVIDERS_LIST_SUCCESS = 'FETCH_PROVIDERS_LIST_SUCCESS',
    FETCH_PROVIDERS_LIST_ERROR = 'FETCH_PROVIDERS_LIST_ERROR',
}

interface FetchProviderRequestAction {
    type: ProviderActionTypes.FETCH_PROVIDER_REQUEST;
}

interface FetchProviderSuccessAction {
    type: ProviderActionTypes.FETCH_PROVIDER_SUCCESS;
    payload: Provider[];
}

interface FetchProviderErrorAction {
    type: ProviderActionTypes.FETCH_PROVIDER_ERROR;
    payload: string;
}

interface FetchProvidersListRequestAction {
    type: ProviderActionTypes.FETCH_PROVIDERS_LIST_REQUEST;
}

interface FetchProvidersListSuccessAction {
    type: ProviderActionTypes.FETCH_PROVIDERS_LIST_SUCCESS;
    payload: ProviderListItem[];
}

interface FetchProvidersListErrorAction {
    type: ProviderActionTypes.FETCH_PROVIDERS_LIST_ERROR;
    payload: string;
}

export type ProviderAction = FetchProviderRequestAction |
    FetchProviderSuccessAction |
    FetchProviderErrorAction |
    FetchProvidersListRequestAction |
    FetchProvidersListSuccessAction |
    FetchProvidersListErrorAction;
