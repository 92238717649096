import { Button, Popconfirm, Space } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { AdblockReportStat } from '../../models/adblock';
import { DeleteAction } from '../../store/types/actions';
import { defaultNotify } from '../common/Notify';

interface AdblockReportActionsProps {
    adblocked: AdblockReportStat;
    unblockCrID: DeleteAction;
}

const AdblockReportActions: React.FC<AdblockReportActionsProps> = props => {
    const { adblocked, unblockCrID } = props;

    const handleUnblock = (crID: string) => {
        unblockCrID(crID);
        defaultNotify(`${crID} cr ID unblocked`, '');
    };

    return (
        <Space size="small">
            <Popconfirm
                placement="topRight"
                title="Are you sure you want to unblock this creative ID?"
                onConfirm={() => handleUnblock(adblocked.cr_id)}
                okText="Yes"
                cancelText="No"
            >
                <Button size="small" icon={<DeleteOutlined />} />
            </Popconfirm>
        </Space>
    );
};

export default AdblockReportActions;
