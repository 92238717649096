import { ProviderAction, ProviderActionTypes } from '../types/provider';
import { Provider, ProviderListItem } from '../../models/provider';
import { authInterceptor } from './interceptors';
import { handleError } from '../../helpers/error';
import { Action } from '../types/actions';

export const fetchProvider = (providerName: string): Action<ProviderAction> => (
    async (dispatch, getState, { defaultAPI }) => {
        dispatch({ type: ProviderActionTypes.FETCH_PROVIDER_REQUEST });

        defaultAPI.interceptors.request.use(authInterceptor(getState().user.jwtToken));

        try {
            const res = await defaultAPI.get<Provider[]>(`providers/${providerName}`);
            dispatch({ type: ProviderActionTypes.FETCH_PROVIDER_SUCCESS, payload: res.data });
        } catch (e: any) {
            handleError(dispatch, e, ProviderActionTypes.FETCH_PROVIDER_ERROR, 'Error fetching provider');
        }
    }
);

export const fetchProvidersList = (): Action<ProviderAction> => (
    async (dispatch, getState, { defaultAPI }) => {
        dispatch({ type: ProviderActionTypes.FETCH_PROVIDERS_LIST_REQUEST });
        defaultAPI.interceptors.request.use(authInterceptor(getState().user.jwtToken));

        try {
            const res = await defaultAPI.get<ProviderListItem[]>('providers');
            dispatch({ type: ProviderActionTypes.FETCH_PROVIDERS_LIST_SUCCESS, payload: res.data });
        } catch (e: any) {
            handleError(dispatch, e, ProviderActionTypes.FETCH_PROVIDERS_LIST_ERROR, 'Error fetching provider list');
        }
    }
);
