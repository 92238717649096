const addLeadingZero = (input: number) => input.toString().padStart(2, '0');
const getMonth = (date: Date) => addLeadingZero((date.getMonth() + 1));

/**
 * Formats date string to readable date string
 * @param input date string
 * @returns YYYY-MM-DD
 */
const formatDate = (input: string) => {
    const date = new Date(input);
    if (Number.isNaN(date.valueOf())) return 'invalid date';

    return `${date.getFullYear()}-${getMonth(date)}-${addLeadingZero(date.getDate())}`;
};

/**
 * Formats date string to readable datetime string
 * @param input date string
 * @returns YYYY-MM-DD HH:MM:SS
 */
const formatDateTime = (input: string) => {
    const date = new Date(input);
    if (Number.isNaN(date.valueOf())) return 'invalid date';
    const d = formatDate(input);
    const hours = addLeadingZero(date.getHours());
    const minutes = addLeadingZero(date.getMinutes());
    const seconds = addLeadingZero(date.getSeconds());

    return `${d} ${hours}:${minutes}:${seconds}`;
};

/**
 * Compares two date strings
 * @param first date string
 * @param second date string
 * @returns number
 */
const compareDates = (first: string, second: string) => {
    const firstDate = new Date(first);
    const secondDate = new Date(second);
    if (Number.isNaN(firstDate.valueOf()) || Number.isNaN(secondDate.valueOf())) return NaN;
    if (firstDate > secondDate) return 1;
    if (firstDate < secondDate) return -1;
    return 0;
};

export {
    formatDate,
    formatDateTime,
    compareDates,
};
